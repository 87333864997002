import {
	createApp
} from 'vue'
import App from './App.vue'

// iconfont
import "@/static/iconfont/iconfont.css"

// Element Plus
// import ElementPlus from 'element-plus';
// import 'element-plus/dist/index.css';
// import * as ElementPlusIconsVue from '@element-plus/icons-vue';
// // Element Plus多语言支持
// import zhCn from 'element-plus/es/locale/lang/zh-cn';

//全局引入Element Plus以及icon，省得麻烦
// app.use(ElementPlus, {
// 	size: 'small',
// 	zIndex: 3000,
// 	locale: zhCn
// });
// for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
// 	app.component(key, component);
// }


//路由
import {
	Routers
} from '@/router'
const app = createApp(App);

//引入路由
app.use(Routers);


app.mount('#app')