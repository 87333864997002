<template>
	<div id="app">
		<div id="uniview">
			<!-- head -->
			<div class="app-header page">
				<div class="app-header__inner">
					<a class="app-header__logo" href="javascript:">
						<h2></h2>
					</a>
					<div class="app-header__main">
						<!-- 导航栏 -->
						<nav>
							<div class="mian-menus">
								<div class="item uop">
									<router-link to="/index">分类首页</router-link>
								</div>
								<div class="item uop">
									<router-link to="/userinfo">个人中心</router-link>
								</div>
								<div class="item uop">
									<router-link to="/setup">信息发布</router-link>
								</div>
								<div class="item uop">
									<router-link to="/privacy">隐私政策</router-link>
								</div>
							</div>
						</nav>

						<!-- 搜索 -->
						<!-- <div class="app-header__search"> 
							<div class="form">
								<input data-header-search-input="" placeholder="请输入关键词" type="text" />
								<button onclick="alert('暂未搜索到该关键词')">
									<span class="icon iconfont" style="font-size: 26px">&#xe603;</span>
								</button>
							</div>
						 </div> -->
						<div class="app-header__obtain">
							<div class="before" id="login">
								<div class="app-header__aside">
									<router-link to="/login">登录</router-link>
								</div>
								<div class="app-header__register">
									<router-link to="/reg">立即注册</router-link>
								</div>
							</div>
						</div>
						<div class="app-header__user">
							<a href="javascript:void(0);">
								<svg viewbox="0 0 1024 1024">
									<path
										d="M511.47776 959.86688c-247.40864 0-448.6656-201.25184-448.6656-448.6656 0-247.36256 201.25696-448.6656 448.6656-448.6656 247.41376 0 448.61952 201.30304 448.61952 448.6656 0 247.41376-201.20576 448.6656-448.6144 448.6656z m0-851.072c-221.92128 0-402.40128 180.5312-402.40128 402.4064s180.48 402.4064 402.40128 402.4064c221.8752 0 402.36032-180.5312 402.36032-402.4064s-180.48512-402.4064-402.36032-402.4064z m0 484.64384c-99.64544 0-180.73088-81.08032-180.73088-180.73088s81.08544-180.736 180.736-180.736c99.64544 0 180.72576 81.08544 180.72576 180.736 0 99.65056-81.08032 180.73088-180.73088 180.73088z m0-315.19744c-74.15808 0-134.46656 60.30848-134.46656 134.46656s60.3136 134.47168 134.46656 134.47168c74.1632 0 134.47168-60.3136 134.47168-134.47168s-60.3136-134.46656-134.47168-134.46656z m225.29024 487.64928a23.168 23.168 0 0 1-22.03136-16.15872c-28.29824-88.76032-109.9776-148.41856-203.25888-148.41856-87.7056 0-167.7312 54.99392-199.18848 136.832a23.02976 23.02976 0 0 1-29.85472 13.29152 23.0912 23.0912 0 0 1-13.30176-29.8496c38.23616-99.59936 135.6288-166.53312 242.35008-166.53312 113.44384 0 212.89472 72.6528 247.31648 180.6336a23.12704 23.12704 0 0 1-15.0016 29.10208 24.8064 24.8064 0 0 1-7.02976 1.09568z"
									></path>
								</svg>
							</a>
						</div>
						<div class="app-header__hamburger">
							<a href="javascript:void(0);">
								<svg viewbox="0 0 1024 1024">
									<path
										d="M943.48985 496.300436c0 14.128789-11.452843 25.582655-25.582655 25.582655L137.130705 521.883091c-14.128789 0-25.582655-11.45489-25.582655-25.582655l0 0c0-14.129812 11.453866-25.582655 25.582655-25.582655l780.77649 0C932.035984 470.717781 943.48985 482.170624 943.48985 496.300436L943.48985 496.300436zM943.48985 167.803797c0-14.129812-11.452843-25.582655-25.582655-25.582655L137.130705 142.221142c-14.128789 0-25.582655 11.452843-25.582655 25.582655l0 0c0 14.128789 11.453866 25.582655 25.582655 25.582655l780.77649 0C932.035984 193.386452 943.48985 181.932586 943.48985 167.803797L943.48985 167.803797zM943.48985 822.766836c0-14.132882-11.452843-25.584702-25.582655-25.584702L137.130705 797.182135c-14.128789 0-25.582655 11.45182-25.582655 25.584702l0 0c0 14.128789 11.453866 25.583678 25.582655 25.583678l780.77649 0C932.035984 848.349491 943.48985 836.894601 943.48985 822.766836L943.48985 822.766836z"
									></path>
								</svg>
							</a>
						</div>
					</div>
				</div>
				<div class="app-header-dropdown hide"></div>
			</div>

			<!-- body -->
			<router-view v-slot="{ route }"></router-view>

			<!-- foot -->
			<div class="app-footer">
				<div class="app-footer__bottom">
					<div class="app-container">
						<div class="copyright">
							<span>版权所有 2024 华蚁人力资源服务（天津）有限公司.保留一切权利。</span>
							<span>地址：天津经济技术开发区滨海-中关村科技园融汇商务园四区9号楼5L21</span>
						</div>
						<div class="icons">
							<a href="http://beian.miit.gov.cn">
								备案号：
								<span>津公网安备 12011602000274号</span>
								&nbsp; &nbsp; &nbsp;
								<span>津ICP备19007966号-1</span>
							</a>
						</div>
					</div>
				</div>
			</div>
			<div class="app-header-drawer hide"></div>
			<div class="app-customer" @click="goTop">
				<div class="app-customer-icons">
					<div class="icon-wrap">
						<!-- <div class="icon icon-2"></div> -->
						<span class="icon iconfont" style="color: #fff;font-size: 35px;">&#xe69b;</span>
						<!-- <div class="popup" style="background-color: rgba(222, 222, 222, 0.8); border-radius: 20px">
							<div class="popup-inner">
								<div class="item">
									<div class="item-icon" style="text-align: center; line-height: 30px">
										<span class="icon iconfont" style="font-size: 26px; color: #000">&#xe636;</span>
									</div>
									<div class="item-infos">
										<a class="list" href="tel:18333566676" onclick="_hmt.push(['_trackEvent', '数字营销', 'click', '右侧400']);">
											<p class="small">服务热线</p>
											<p class="big">18333566676</p>
										</a>
									</div>
								</div>
								<div class="item">
									<div class="item-icon" style="text-align: center; line-height: 30px">
										<span class="icon iconfont" style="font-size: 26px; color: #000">&#xe6b4;</span>
									</div>
									<div class="item-infos">
										<a
											class="list"
											data-button-inquire="在线帮助"
											href="javascript:"
											onclick="_hmt.push(['_trackEvent', '数字营销', 'click', '右侧在线帮助']);"
										>
											<p class="big">在线帮助</p>
											<p class="small">填写表单，让客服与您联系</p>
										</a>
									</div>
								</div>
								<div class="item">
									<div class="item-icon" style="text-align: center; line-height: 30px">
										<span class="icon iconfont" style="font-size: 26px; color: #000">&#xe68b;</span>
									</div>
									<div class="item-infos">
										<a
											class="list"
											href="javascript:"
											onclick="_hmt.push(['_trackEvent', '数字营销', 'click', '右侧在线客服']);"
											rel="noopener noreferrer"
											target="_self"
										>
											<p class="big">在线客服</p>
											<p class="small">工作日：08:30-18:00</p>
										</a>
									</div>
								</div>
								<div class="item">
									<div class="item-icon" style="text-align: center; line-height: 30px">
										<span class="icon iconfont" style="font-size: 26px; color: #000">&#xe63c;</span>
									</div>
									<div class="item-infos">
										<a
											class="list"
											data-button-inquire="售后咨询"
											href="javascript:"
											onclick="_hmt.push(['_trackEvent', '数字营销', 'click', '右侧售后咨询']);"
										>
											<p class="big">售后咨询</p>
											<p class="small">售后客服为您答疑</p>
										</a>
									</div>
								</div>
							</div>
						</div> -->
						<!--  -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script name="App" setup>
import { ref } from 'vue';
const goTop = () => {
	window.scrollTo({
		top: 0,
		behavior: 'smooth' // 平滑滚动（可选）
	});
};
</script>

<style>
@import url('common/style/style.css');
@import url('common/style/swiper.css');
@import url('common/style/uniview.css');
/* #app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	margin-top: 60px;
} */
</style>
