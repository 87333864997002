<template>
	<div class="app-body" v-for="item in gdxxdata" :key="item.id">
		<div>
			<!-- head -->
			<div class="app-banner__page sticky">
				<div class="app-banner__page-inner" style="background: rgba(0, 0, 0, 0.35)">
					<div class="app-container">
						<div class="inner">
							<h1 class="title">{{ item.title }}</h1>
						</div>
					</div>
				</div>
			</div>

			<!-- body -->
			<div id="submenu-sticky">
				<!-- 方案介绍 -->
				<div class="wrapper-pattern-5" id="column-intor">
					<div class="app-container">
						<div class="wrapper-content">
							<div class="wrapper-content app-medical-intor">
								<article class="description_con">
									<div class="gd-title">
										<h1>{{ item.title }}</h1>
									</div>
									<div class="gd-place">
										<p style="font-size: 20px">
											<span>【地点】</span>
											<span>{{ item.place }}</span>
										</p>
									</div>
									<div class="gd-quality">
										<p style="font-size: 20px">
											<span>【质量要求】</span>
											<span style="color: red">{{ item.quality }}</span>
										</p>
									</div>
									<div class="inner-intor" v-if="item.innerIntroTitle">
										<br />
										【{{ item.innerIntroTitle }}】
										<div class="jx-item-box">
											<div class="jx-item" v-for="i in item.innerIntro">
												<div v-if="item.innerIntro">
													<span class="jx-title">{{ i.title }}：</span>
													<span class="jx-text">{{ i.text }}</span>
												</div>
											</div>
										</div>
									</div>
									<div class="gd-range">
										<p style="font-size: 20px">
											<span>【相关内容】</span>
										</p>
										<p style="font-size: 16px; line-height: 50px">
											<span>{{ item.range }}</span>
										</p>
									</div>

									<div class="gd-introduce">
										<p style="font-size: 20px">【相关介绍】</p>
										<p style="font-size: 16px; line-height: 50px">
											{{ item.introduce }}
										</p>
									</div>

									<!-- <div class="gd-image">
										<p style="font-size: 20px">【相关展示】</p>
										<br />
										<div class="img-box" v-for="img in item.image">
											<img :src="img" alt="" />
										</div>
									</div> -->
								</article>
							</div>
						</div>
					</div>
				</div>
				<!-- 方案介绍 -->
				<footzxVue></footzxVue>
			</div>
		</div>
	</div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import footzxVue from '@/components/foot-zx/foot_zx.vue';
// 工地信息
import { GDXXDataJS } from '@/common/utils/gdxxdata';
import { onMounted } from 'vue';

// 接收路由传参
let route = useRoute();
const jxitemid = route.path.substr(route.path.length - 4, 4);
const gdxxdata = GDXXDataJS().filter((item) => {
	return item.id == jxitemid;
});

console.log('---------------------------------');
console.log(jxitemid);
console.log(gdxxdata);

onMounted(() => {
	window.scrollTo({
		top: 0,
		// behavior: 'smooth' // 平滑滚动（可选）
	});
});
</script>

<style>
.jx-item-box {
	margin-left: 30px;
}
.jx-item {
	display: flex;
	align-items: center;
}

.description_con > div {
	margin: 30px 0;
}
</style>
