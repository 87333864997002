export function JXZRDataJS() {
	const jxdata = [
		// zr01
		{
			id: 'zr01',
			title: '天津出售转让二手2021年徐工XE210DA挖掘机',
			introduce: '出售转让二手XE210DA挖掘机,设备当前在天津。这台机器的生产时间是2021年。当前工作小时数为不详小时。 感兴趣的可以询价,欢迎各位老板、个人、公司和单位前来看车!!捡漏,急售全车原版 外观漂亮。',
			price: '面议',
			zruser: '徐先生',
			mode: '电话联系',
			telphone: '18651775938',
			innerIntroTitle: '',
			innerIntro: [],
			image: [
				'https://vip-static.lmjx.net/cmused/202308/22/640w854hf_1692691908232851.jpg!600x400.jpg',
				'https://vip-static.lmjx.net/cmused/202308/22/854w640hf_1692691908582891.jpg!600x400.jpg',
				'https://vip-static.lmjx.net/cmused/202308/22/854w640hf_1692691908904945.jpg!600x400.jpg',
				'https://vip-static.lmjx.net/cmused/202308/22/854w640hf_1692691909213139.jpg!600x400.jpg'
			],
		},
		// zr02
		{
			id: 'zr02',
			title: '天津出售转让二手2005年日立ZX200挖掘机',
			introduce: '出售转让二手ZX200挖掘机,设备当前在天津。这台机器的生产时间是2005年。当前工作小时数为不详小时。感兴趣的可以询价,欢迎各位老板、个人、公司和单位前来看车!!捡漏,急售，05年日立我200-6 车身扫漆！地轮有换新',
			price: '面议',
			zruser: '王先生',
			mode: '电话联系',
			telphone: '18651775938',
			innerIntroTitle: '',
			innerIntro: [],
			image: [
				'https://vip-static.lmjx.net/cmused/202308/22/1280w1707hf_1692691893069903.jpg!600x400.jpg',
				'https://vip-static.lmjx.net/cmused/202308/22/4096w3072hf_1692691891729640.jpg!600x400.jpg',
				'https://vip-static.lmjx.net/cmused/202308/22/1702w1276hf_1692691892472573.jpg!600x400.jpg',
				'https://vip-static.lmjx.net/cmused/202308/22/1280w1707hf_1692691893069903.jpg!600x400.jpg'
			],
		},
		// zr03
		{
			id: 'zr03',
			title: '天津出售转让二手2011年斗山DH500LC-7挖掘机',
			introduce: '出售转让二手DH500LC-7挖掘机,设备当前在天津。这台机器的生产时间是2011年。当前工作小时数为不详小时。感兴趣的可以询价,欢迎各位老板、个人、公司和单位前来看车!!捡漏,急售。带9成新艾迪195',
			price: '面议',
			zruser: '李先生',
			mode: '电话联系',
			telphone: '15150033111',
			innerIntroTitle: '',
			innerIntro: [],
			image: [
				'https://vip-static.lmjx.net/cmused/202306/22/854w640hf_1687392005601402.jpg!600x400.jpg',
				'https://vip-static.lmjx.net/cmused/202306/22/854w640hf_1687392005301493.jpg!600x400.jpg',
				'https://vip-static.lmjx.net/cmused/202306/22/854w640hf_1687392004957602.jpg!600x400.jpg',
				'https://vip-static.lmjx.net/cmused/202306/22/890w1920hf_1687392004638038.jpg!600x400.jpg'
			]
		},
		// zr04
		{
			id: 'zr04',
			title: '天津出售转让二手龙工装载机(加长臂配置高)',
			introduce: '出售转让二手装载机,设备当前在天津。感兴趣的可以询价,欢迎各位老板、个人、公司和单位前来看车!!公司上料用闲置装载机2台出售 龙工20装载机一台，（大轮胎、加长臂、带涡轮增压）。高配置，新车一次没用过，半价出售4万2千元另有一台30临工装载机出售。加长臂，高配置。仅仅用了半年多时间，工作 480小时。以前是拌料用的，好车一眼就能看出来。有需要的朋友来我方看车。买回去直接使用。手续齐全欢迎购买。随车工具，打黄油的。还备有一些保养零部件可以提供给买方。',
			price: '4.2万元',
			zruser: '赵先生',
			mode: '电话联系',
			telphone: '18876639051',
			innerIntroTitle: '',
			innerIntro: [],
			image: [
				'https://vip-static.lmjx.net/cmused/202005/13/600w270hf_1589370559030954.jpg!600x400.jpg'
			]
		},
		// zr05
		{
			id: 'zr05',
			title: '天津出售转让二手九成新龙工855B装载机',
			introduce: '出售转让二手装载机,设备当前在天津。感兴趣的可以询价,欢迎各位老板、个人、公司和单位前来看车!!全款购买的龙工855和柳工855装载机各一台，全车九成多新，工作在400和800小时左右，发动机是新款发动机，随车工具齐全，手续齐全，有需要的朋友前来看车试车，满意为止柳工855临工953临工933加长臂铲车不用了处理转让处理自家无用的铲车使用了500多小时车还有9成新加长臂去年9成新柳工855，工作700小时，手续齐全潍柴斯太尔发动机，车况很好没诚心要的，就别打电话了，欢迎客户现场试车看车原装原漆，欢迎客户现场试车看车原装原漆 低价转让两台装载机，一台柳工855装载机，一台是龙工855B装载机，潍柴发动机，仅仅用了600小时，一年车，整车九成新，几乎没怎么用。都有专业人士驾驶保养得。手续齐全。以前是拌灰用的，工程现在已完工，欢迎用户直接购买，谁买谁合适。预购从速。先到先得。',
			price: '面议',
			zruser: '李先生',
			mode: '电话联系',
			telphone: '13011373603',
			innerIntroTitle: '',
			innerIntro: [],
			image: [
				'https://vip-static.lmjx.net/cmused/202005/13/600w360hf_1589370559530955.jpg!600x400.jpg'
			]
		},
		// zr06
		{
			id: 'zr06',
			title: '天津出售转让二手柳工855装载机，无大修基本没怎么用',
			introduce: '出售转让二手装载机,设备当前在天津。感兴趣的可以询价,欢迎各位老板、个人、公司和单位前来看车!!-保证纯个人一手柳工50龙工装载机30长臂出售50临工953N长臂8万元，两台没怎么用，手续齐全工作 价出售自己料场用的50柳工855，临工953N装载机，由于料场关停不赚钱不得不赔钱转让，全款购车手续齐全，无大修，刚刚工作八百多小时，需要的电话详谈。低价急转闲置未用的柳工855和龙工855D装载机临工30加长臂2015年装载机由于工程到期了，好几个月都没找到新的工程，现在要卖掉这两台装载机了，用的时间还不长呢，型号是柳工855、临工933装载机柳工855龙工855d装载机）2015年的车潍柴斯太尔发动机工作550小时车况95成新一直修路铲土用的没干活累活由于工程完工实在没活准备处理掉！个人料场出售50龙工，临工装载机潍柴动力，龙工855D售价13万，临工933售价7万，几乎就没怎么用过，车用的非常省。全款购车，原车图片，绝对让您买回去用的踏实企业简介 个人二手装载机出售50龙工855D长臂临工933铲车30柳工855装载机50长臂车子九成新，工作几百小时，手续齐全，看车满意帮忙联系物流个人用的龙工装载机，一直是自己使用，但生意不景气，一直没怎么用过，工作时间少，装载机还和新的一样，车子好用，液压系统正常，机器变速箱没有修过，原车的。好车不等人，可随便试车。',
			price: '6.62万元',
			zruser: '孙先生',
			mode: '电话联系',
			telphone: '13132106639',
			innerIntroTitle: '',
			innerIntro: [],
			image: [
				'https://vip-static.lmjx.net/cmused/202005/13/600w360hf_1589370560190956.jpg!600x400.jpg'
			]
		},
		// zr07
		{
			id: 'zr07',
			title: '天津出售转让二手临工933装载机车况好原装原漆',
			introduce: '出售转让二手装载机,设备当前在天津。感兴趣的可以询价,欢迎各位老板、个人、公司和单位前来看车!!-超低价转让个人去年全款购买的龙工855D和柳工855装载机各一台，全车九成多新，工作在400和800小时左右，发动机是新款发动机，随车工具齐全，手续齐全，有需要的朋友前来看车试车，满意为止柳工855临工953临工933加长臂30铲车不用了处理转让 处理自家无用的铲车使用了600多小时车还有9成新加长臂去年9成新柳工855，工作500小时，手续齐全潍柴斯太尔发动机，车况很好没诚心要的全款购买的龙工855D和柳工855装载机各一台，全车九成多新，工作在400和800小时左右，发动机是新款发动机，随车工具齐全，手续齐全，有需要的朋友前来看车试车，满意为止柳工855临工953临工933加长臂铲车不用了处理转让 处理自家无用的铲车使用了500多小时车还有9成新加长臂去年9成新柳工855，工作700小时，手续齐全潍柴斯太尔发动机，车况很好没诚心要的，就别打电话了，欢迎客户现场试车看车原装原漆，欢迎客户现场试车看车原装原漆 低价转让两台装载机，一台柳工855装载机，一台是龙工855B装载机，潍柴发动机，仅仅用了600小时，一年车，整车九成新，几乎没怎么用。都有专业人士驾驶保养得。手续齐全。以前是拌灰用的，工程现在已完工，欢迎用户直接购买，谁买谁合适。预购从速。先到先得。超低价格处理3台装载机，该车年限一年左右的急转30临工933、柳工855装载机，九成新，工作有500多小时了，龙工855D装载机50保养的好，正常工作',
			price: '6.5万元',
			zruser: '石老板',
			mode: '电话联系',
			telphone: '13212051138',
			innerIntroTitle: '',
			innerIntro: [],
			image: [
				'https://vip-static.lmjx.net/cmused/202005/13/600w232hf_1589370554730948.jpg!600x400.jpg'
			]
		},
		// zr08
		{
			id: 'zr08',
			title: '天津出售转让工地干活二手山东临工装载机按时保养',
			introduce: '出售转让二手装载机,设备当前在天津。感兴趣的可以询价,欢迎各位老板、个人、公司和单位前来看车!!处理14年的临工953、龙工855B加长臂装载机，车子一直在工地使用，按时保养，机器变速箱滴油不漏，原车图片，有需要的朋友电话联系​',
			price: '10万元',
			zruser: '张总',
			mode: '电话联系',
			telphone: '13032298196',
			image: [
				'https://vip-static.lmjx.net/cmused/202005/13/600w310hf_1589370534180908.jpg!600x400.jpg'
			],
			innerIntroTitle: '',
			innerIntro: []
		}
	]
	return jxdata;
}