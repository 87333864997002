<template>
	<div class="app-body" v-for="item in jxdataitem" :key="item.id">
		<div>
			<!-- head -->
			<div class="app-banner__page sticky">
				<div class="app-banner__page-inner" style="background: rgba(0, 0, 0, 0.35);">
					<div class="app-container">
						<div class="inner">
							<h1 class="zr-title">{{item.title}}</h1>
						</div>
					</div>
				</div>
			</div>
			
			<!-- body -->
			<div id="submenu-sticky">
				<!-- 方案介绍 -->
				<div class="wrapper-pattern-5" id="column-intor">
					<div class="app-container">
						<div class="wrapper-content">
							<div class="wrapper-content app-medical-intor">
								<article class="description_con">
									<h1>{{ item.title }}</h1>
									
									<br />
									<p style="font-size: 20px">
										<span>【价格】</span>
										<span style="color: red;">{{item.price}}</span>
									</p>
									<br />
									<p style="font-size: 20px">
										<span>【联系人】</span>
										<span>{{item.zruser}}</span>
									</p>
									<br />
									<p style="font-size: 20px">
										<span>【联系方式】</span>
										<span>{{item.telphone}}</span>
									</p>
									<br />
									<p v-if="item.innerIntroTitle">
										{{item.innerIntroTitle}}
										<div class="jx-item-box">
											<div class="jx-item" v-for="i in item.innerIntro">
												<div v-if="item.innerIntro">
													<span class="jx-title">{{i.title}}：</span>
													<span class="jx-text">{{i.text}}</span>
												</div>
											</div>
										</div>
									</p>
									<br />
									<p style="font-size: 20px">【相关介绍】</p>
									<br />
									<p style="font-size: 16px; line-height: 50px">
										{{item.introduce}}
									</p>
									<br />
									<br />
									<p style="font-size: 20px">【相关展示】</p>
									<br />
									<p style="font-size: 16px; line-height: 50px">
										<div class="img-box" v-for="img in item.image">
											<img :src="img" alt="" />
										</div>
									</p>
								</article>
							</div>
						</div>
					</div>
				</div>
				<!-- 方案介绍 -->

				<!-- 咨询联系 -->
				<footzxVue></footzxVue>
			</div>
		</div>
	</div>
</template>

<script setup>
import footzxVue from '@/components/foot-zx/foot_zx.vue';
import { JXZRDataJS } from '../../../common/utils/jxzrdata.js';
import { useRoute } from 'vue-router';
import { onMounted } from 'vue';

// 接收路由传参
let route = useRoute()
const jxitemid = route.path.substr(route.path.length-4,4);
const jxdataitem = JXZRDataJS().filter((item)=>{
	return item.id == jxitemid;
})

console.log('---------------------------------');
console.log(jxitemid);
console.log();

onMounted(() => {
	window.scrollTo({
		top: 0,
		// behavior: 'smooth' // 平滑滚动（可选）
	});
});

</script>

<style>
.jx-item-box {
	margin-left: 30px;
}
.jx-item {
	display: flex;
	align-items: center;
}
</style>
