<template>
	<div class="app-body" data-scroll-section="">
		<div class="sticky"></div>
		<div id="submenu-sticky">
			<div class="app-submenu current hide" data-scroll="" data-scroll-sticky="" data-scroll-target="#submenu-sticky">
				<div class="app-container">
					<div class="app-submenu-wrap">
						<ul class="app-submenu-crumbs">
							<li><a href="javascript:">首页</a></li>
							<li><a href="">隐私政策</a></li>
						</ul>
					</div>
				</div>
			</div>
			<div class="wrapper-main">
				<div class="app-container">
					<div class="">
						<div class="">
							<div class="app-content-head" data-scroll="">
								<h1 style="text-align: center">华蚁人力机械信息服务《隐私政策》</h1>
							</div>
							<div class="app-content-main">
								<p align="right">更新日期：2024年5月1日</p>
								<p>感谢您使用华蚁人力机械信息服务！</p>
								<p>
									华蚁人力资源服务（天津）有限公司（以下简称“
									<b><u>华蚁人力</u></b>
									”或“
									<b><u>我们</u></b>
									”）华蚁机械信息服务非常重视您的个人信息与隐私权益，致力于维护您对我们的信任。我们恪守以下原则保护您的个人信息：
									<b><u>诚信原则、同意和选择原则、目的明确原则、收集限制原则、最少够用原则、公开透明原则、权责一致原则、确保安全原则、隐私合规原则</u></b>
									。
								</p>
								<p>
									本隐私政策旨在帮助您了解：1）我们收集您个人信息的内容；2）我们所使用采集的方式；3）我们采集您个人信息的用途；4）我们如何保护您个人信息以及您的个人信息权益；5）您疑问的解答与联系我们的方式。
									<b>
										<u>
											了解这些内容，对于您行使个人权利及保护您的个人信息至关重要，请您在使用或接受产品服务前，务必认真阅读本政策。特别说明的是，本政策不适用于其他第三方向您收集个人信息。
										</u>
									</b>
								</p>
								<p>本政策包含以下内容章节：</p>
								<p>(一)个人信息定义</p>
								<p>(二)我们如何收集和使用您的个人信息</p>
								<p>(三)个人信息共享、转让和披露</p>
								<p>(四)信息存储与保护</p>
								<p>(五)您的权利</p>
								<p>(六)第三方服务</p>
								<p>(七)未成年人的隐私</p>
								<p>(八)联系我们</p>
								<p>(九)隐私政策修订</p>
								<p>
									<b>(一)</b>
									<b>个人信息与非个人信息的定义</b>
								</p>
								<p>
									<b></b>
									根据《中华人民共和国个人信息保护法》，
									<u>个人信息</u>
									是指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，包括但不限于姓名、性别、
									<u>身份证号码</u>
									、出生日期、
									<u>住所地</u>
									、
									<u>电话号码</u>
									、邮箱地址、
									<u>邮寄地址</u>
									、
									<u>个人视频内容</u>
									。
								</p>
								<p>
									非个人信息是指除个人信息外的，利用其本身无法与任何特定的个人直接建立联系的数据，如职业、语言、邮编、区号、序列号、URL、自动记录的访问数据（如浏览器种类、域名、网页浏览数、引荐网页、IP地址和页面导航）、产品及您的移动设备的唯一产品标识符、不包含个人信息的视频内容、接入产品所在的国家和时区、地理定位、移动电话运营商标识、设备软件平台和硬件信息等。
								</p>
								<p>
									<b>(二)</b>
									<b>我们如何收集和使用您的个人信息</b>
								</p>
								<p>
									1.
									在您使用华蚁人力产品或服务而创建账户或为访问、查看在线视频而注册账号时，我们需要您通过产品客户端提供您的用户名、密码、手机号码、邮箱、验证码、产品名称、产品验证码和序列号。前述信息用途：1）用户名、密码、手机号码、邮箱、验证码是为了满足各地区相关法律法规的网络实名制要求；2）安装和激活产品时，如该产品连接华蚁人力信息服务并与您的账户进行绑定，那么我们可能会要求您提供部分具有唯一性的产品基本信息，如产品名称、产品验证码和序列号。如果您拒绝提供这些信息，可能无法正常使用我们的产品和/或服务。
								</p>
								<p>
									2.
									<b><u>在您初次登录华蚁人力信息提供服务</u></b>
									<b><u>账号时</u></b>
									，我们会收集您的以下信息并采取适当的控制措施：
								</p>

								<table class="table-plan" width="100%">
									<tbody>
										<tr>
											<th width="30%">采集信息</th>
											<th colspan="4" width="35%">控制方式</th>
											<th width="35%">用途</th>
										</tr>
										<tr>
											<td>使用手机型号</td>
											<td colspan="4" rowspan="3" valign="top">
												首次使用时通过弹窗方式提醒，另当采集方式、内容、用途发生变化时,将通知您变动信息并获取您的同意
											</td>
											<td colspan="4" rowspan="3" valign="top">问题定位辅助信息 判断信息推送服务</td>
										</tr>
										<tr>
											<td>手机系统及版本</td>
										</tr>
										<tr>
											<td>手机连接的网络信息</td>
										</tr>
									</tbody>
								</table>
								<br />
								<p>
									3.
									在您使用华蚁人力信息服务保存视频相关功能时，如视频剪辑、视频直播流、图像（用户内容）、消息提醒，我们将收集您的摄像机拍摄的视频截图，从而将截图推送给您，以便您能保存您所需要的内容。
								</p>
								<p>
									4.
									在您使用消息提醒功能时，我们需要收集您的客户端唯一特征值（如根据设备的IMEI或udid生成的唯一值）信息和手机语言，目的是为了给您提供精确的报警消息推送和报警信息推送语言。
									<b><u>如您拒绝提供这些信息仅会使您无法使用报警订阅功能，但不影响您正常使用其他功能</u></b>
									。此外，您也可以选择关闭该功能并清除客户端唯一特征值信息。
								</p>
								<p>
									5.
									<b>
										<u>
											在您使用设备添加功能时，我们可能需要收集您的设备序列号、设备注册码、设备密码、设备型号和客户端WIFI信息，目的是为了进行设备配网和设备绑定，拒绝提供这些信息仅会使您无法添加设备。
										</u>
									</b>
								</p>
								<p>
									6. 在您使用我们的设备接入服务时，
									<b>
										<u>
											为保障您正常使用我们的设备接入服务，我们会收集您的设备型号、唯一设备标识符、设备IP地址、设备MAC地址、软件版本号、接入网络的方式和类型、设备经纬度（如设备支持）、操作记录信息，这类信息是为提供服务必须收集的基础信息。若您不提供这类信息，您可能无法正常使用我们的设备接入服务
										</u>
									</b>
									。
								</p>
								<p>
									7.
									当您使用实时预览功能时，我们可能需要收集您的设备序列号、设备IP、设备端口信息、视频码流信息，目的是为了给您提供设备的实时码流。拒绝提供这些消息仅会使您无法使用实时预览功能，但不影响您正常使用其他功能。
								</p>
								<p>
									8. 当您使用基于用户基准信息的分析、对比功能和告警服务时（包括面部/人形识别、车牌/车辆识别、考勤管理、指纹开锁，如设备支持），您需要预先录入
									<b><u>面部图片、车牌信息、考勤人员信息、指纹</u></b>
									的基准信息，拒绝提供这些信息仅会使您无法使用相关的分析、对比功能和告警服务，不影响您正常使用其他功能。
								</p>
								<p>
									9.
									当您使用设备分享功能时，我们需要收集您的设备序列号、昵称、被分享者用户名，目的是为了将您的设备分享给其他人。拒绝提供这些消息仅会使您无法使用设备分享功能，但不影响您正常使用其他功能。分享动作仅为每次分享每次使用，不会进行长期的分享。
								</p>
								<p>
									10. 当您需要华蚁人力为您提供支持服务时，您通过邮件、电话或使用我们的用户支持工具与我们的联系过程中，
									<b><u>我们需要收集您的姓名、联系电话/邮箱、所在单位、设备使用信息、设备条码、设备登录账号信息</u></b>
									，以验证您的产品和身份的准确性，为您提供精准的服务支持。如果您不提供这些信息，我们将无法为您提供支持服务。
								</p>
								<p>11. 在其他可能存在的不同场景下，我们还会采集您的个人信息，包括但不限于：</p>
								<p>&nbsp;&nbsp;1) 我们在与您的电话沟通时，需要进行录音，以便作为服务证据、提升服务能力与水平，可以为您持续提供优质的售后服务。</p>
								<p>&nbsp;&nbsp;2) 如涉及到需要您签署远程接入同意证明的，我们会收集到您的手工签字或单位盖章。</p>
								<p>
									&nbsp;&nbsp;3)
									<b>
										<u>
											当您进行设备报修时，可能需要您将设备寄回进行检修并提供收件地址，如果寄回的设备包含涉及个人隐私的数据，请您根据实际需求备份后，自行格式化后再寄回。
										</u>
									</b>
								</p>
								<p>
									&nbsp;&nbsp;4)
									<b>
										<u>
											当您忘记设备密码需要重新生成时，我们会收集您的身份证信息、手持身份证照片、签署您的手工签字的申请函以及免责声明。我们需要通过核对这些信息来尽可能判断您是否为产品目前的合法使用者，从而来决定是否接受您的申请
										</u>
									</b>
									。
								</p>
								<p>
									&nbsp;&nbsp;5)
									当您进入我们产品的测试/展示区域，根据测试/展示产品的不同，我们可能会收集您的图像和/或视频，前述图像、视频在测试/展示结束时删除；您可以通过离开前述测试/展示区域拒绝图像和/或视频收集。
								</p>
								<p>
									12.
									<b>
										<u>
											华蚁人力为了给您带来更好的产品体验、防止误用以及持续提升您的用户感受，我们会收集行业标准的“非个人信息”（见本文第一章节定义），这些信息并不会侵犯用户隐私权益，
										</u>
									</b>
									如果您对移动设备的安全和隐私设置有疑问的话，请查阅您所使用的移动服务提供商或移动设备制造商的说明书来调整您的设置。前述收集行为包括：
								</p>
								<p>&nbsp;&nbsp;1) 我们可能会记录您通过华蚁人力服务对产品所作的一些调整，并且我们会将收集的非个人信息与产品直接收集的信息一起存储。</p>
								<p>
									&nbsp;&nbsp;2)
									我们也会使用cookies、网站信标、像素标签和其他技术来记录、存储您的设置、收集一些非个人信息，如日志数据和设备数据。尤其是，我们使用的cookies允许我们将您的酉昔科技服务使用行为和我们存储的您的账户信息或在您使用酉昔科技服务过程中我们存储的其他信息进行联系，这可以帮助我们更好地判断和解决您的问题，以及帮助我们管理和提升酉昔科技服务和产品，进而提升您使用我们酉昔科技服务的效果。您可以通过拒绝在浏览器设置中使用cookie来做拒绝我们收集cookies信息。
								</p>
								<p>
									&nbsp;&nbsp;3)
									当您使用产品的录音或流媒体功能时，我们会在征得您同意后，从产品记载和传输视频和/或音频。这可能会包含截图并在邮件发送的通知中附上部分截图信息，分析数据的情形。
								</p>
								<p>
									<b>(三)</b>
									<b>个人信息的共享、转让和披露</b>
								</p>
								<p>1. 共享</p>
								<p>
									华蚁人力在为您提供某些特定服务时，需要与授权合作伙伴、经慎重挑选的服务供应商或国家监管机构共享您的个人信息，且我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的共享方组织无权将共享的个人信息用于与产品或服务无关的其他用途。对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的数据保护协定，要求其按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。
								</p>
								<p>
									<b>&nbsp;&nbsp;1)</b>
									<b><u>我们需要与短信运营商共享您的手机号码才能为您完成账号注册；</u></b>
								</p>
								<p>
									<b>&nbsp;&nbsp;2)</b>
									<b><u>我们会和手机厂商共享您的手机设备信息、告警信息等，才能给您提供告警推送；</u></b>
								</p>
								<p>
									<b>&nbsp;&nbsp;3)</b>
									<b><u>我们需要和云服务商共享您的设备信息、账号信息内容，才能为您提供核心的视频监控功能；</u></b>
								</p>
								<p>
									<b>&nbsp;&nbsp;4)</b>
									<b>
										<u>
											您可以向特定的对象共享您的视频、身份信息等，也可以基于我们的产品与/或服务向不特定人共享自己的视频、位置信息、身份信息等。当您因为您的共享行为导致您的信息发生泄露等严重情况时，华蚁人力不承担相关法律责任。
										</u>
									</b>
								</p>
								<p>2. 转让</p>
								<p>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
								<p>&nbsp;&nbsp;1) 获得您明确的同意后；</p>
								<p>
									&nbsp;&nbsp;2)
									在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此个人信息保护政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
								</p>
								<p>3. 披露</p>
								<p>我们仅会在以下情况下，公开披露您的个人信息：</p>
								<p>&nbsp;&nbsp;1) 获得您明确的同意后；</p>
								<p>
									&nbsp;&nbsp;2)
									请您理解，根据《中华人民共和国网络安全法》、《中华人民共和国个人信息保护法》、《信息安全技术个人信息安全规范》等所适用的法律法规及国家标准，在下列情形中，披露您的个人信息无需征得您的同意：
								</p>
								<p>&nbsp;&nbsp;&nbsp;&nbsp;• 与国家安全、国防安全直接相关的；</p>
								<p>&nbsp;&nbsp;&nbsp;&nbsp;• 与公共安全、公共卫生、重大公共利益直接相关的；</p>
								<p>&nbsp;&nbsp;&nbsp;&nbsp;• 与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
								<p>&nbsp;&nbsp;&nbsp;&nbsp;• 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</p>
								<p>&nbsp;&nbsp;&nbsp;&nbsp;• 所收集的个人信息是您自行向社会公众公开的；</p>
								<p>&nbsp;&nbsp;&nbsp;&nbsp;• 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
								<p>&nbsp;&nbsp;&nbsp;&nbsp;• 根据您的要求签订合同所必需的；</p>
								<p>&nbsp;&nbsp;&nbsp;&nbsp;• 用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</p>
								<p>
									&nbsp;&nbsp;&nbsp;&nbsp;•
									学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
								</p>
								<p>
									&nbsp;&nbsp;&nbsp;&nbsp;•
									遵守包括我们所运营业务相关国家的法律规定和政府命令、以及法律执法机构、监管机构和法院的法律程序或类似法律程序、响应传票。
								</p>
								<p>
									<b>(四)</b>
									<b>个人信息存储与保护</b>
								</p>
								<p>1. 信息存储</p>
								<p>
									除非法律另有明文规定或经您允许，我们只会在本隐私政策所属目的所需的期限内保留您的个人信息。在您使用我们的产品或接受服务期间，我们将持续为您保存您的个人信息，如果您注销账号或主动删除个人信息，我们将在您提出申请的48小时内完成对您的个人信息的删除或匿名化处理，除非法律另有规定或协议另有约定。
								</p>
								<p>另，依据相关地方法律要求，华蚁人力在业务发生地运营中收集和产生的个人信息，存储在业务适用地。</p>
								<p>2. 个人信息安全保护</p>
								<p>
									我们非常重视信息安全，华蚁人力成立了专责团队负责研发和应用多种安全技术和程序，采用数据加密、部署访问控制机制建立相关内控制度，确保授权人员才可访问个人信息、向涉及的人员宣传安全和隐私保护要求、向全员宣传信息安全保护制度等保护措施。同时，我们已取得信息安全管理体系认证（ISO27001国际标准），并且会定期聘请外部独立第三方对我们的信息安全管理体系进行评估。通过以上这些严格措施努力保护您的信息不被未经授权的访问、使用或泄露。但请您理解，在互联网行业由于当前技术的限制以及可能存在的各种恶意攻击手段，即便我们竭尽所能加强安全措施，也不可能保证信息的百分之百安全。请您了解，您使用我们的产品和/或服务时所用的系统和通讯网络，有可能因超出我们控制能力之外的其他因素而出现安全问题。
								</p>
								<p>
									同时，您的个人信息的安全性也依赖您在安全措施方面的配合。我们提供给您用户名和密码，以便访问相关产品、网站或服务，您将负责这些信息的保密。我们请求您不要和任何人分享您的此类信息，我们也会定期审查所使用的技术和实现信息收集的策略。
								</p>
								<p>我们建立专门的管理制度、流程和组织以保障信息的安全。我们严格限制访问信息的人员范围，要求他们遵守保密义务，并进行审计。</p>
								<p>若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，并及时以推送通知、公告等形式告知您。</p>
								<p>
									<b>(五)</b>
									<b>您的权利</b>
								</p>
								<p>
									保存您的个人信息可以为您提供您需要的关联服务。您可以通过您的账户来访问和更新您的个人信息，如果您无法找到您的个人信息，可以通过向longqianzhang163@163.com发送电子邮件或致电15522159984的方式来联系我们获取，或要求我们对您提供的数据予以更改、撤回或删除。
								</p>
								<p>
									根据适用的法律，我们保障您对自己的个人信息行使以下权利：访问或更正个人信息，删除个人信息，改变授权同意的范围或撤销授权，注销账号，获取个人信息副本。请您注意，在以下情况下，您有权利要求我们删除您的个人信息：1）我们违反法律法规规定，收集、使用个人信息；2）我们违反了与用户的约定，收集、使用个人信息。
								</p>
								<p>
									为了行使您的权利，请通过本隐私政策中载明的联系方式联系我们，对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们有权视情收取一定成本费用；对于无端重复、需要过多技术手段、给他人合法权益带来风险或者非常不切合实际的请求，我们有权予以拒绝。如果您对我们如何处理您的个人信息感到担忧，您有权向您居住国的数据保护机构提交投诉。
								</p>
								<p>
									为了向您提供相关服务，在您持续使用华蚁人力的产品和/或服务的前提下，我们会将您的个人信息存放在我们的服务器上。保存您的最新个人信息是为了提供给您相关的服务。您可以通过个人中心来访问、更新、删除您的个人信息。我们可以帮助您管理您的订阅，停用您的帐户，并删除您的活动概要和数据。
								</p>
								<p><b></b></p>
								<p>
									<b>(六)</b>
									<b>第三方服务</b>
								</p>
								<p>
									我们的产品与/或服务中可能接入或链接至第三方提供的网站或其他服务。如您可利用“分享”键将某些您的信息资料分享到第三方服务。这些功能可能会收集您的信息（包括您的日志信息），从而正常运行上述功能。我们仅会出于正当、必要、特定的目的共享您的信息。对我们与之共享信息的第三方服务商，我们会要求他们履行相关保密义务并采取相应的安全措施。
								</p>
								<p>
									为实现本政策中声明的目的，我们可能会接入第三方服务商提供的SDK或其他类似的应用程序，并将我们依照本政策收集的您的某些信息共享给该等第三方服务商，以便提供更好的客户服务和用户体验。目前，我们接入的第三方服务商包括以下几种类型：
								</p>
								<p>&nbsp;&nbsp;1) 用于消息推送功能，包括手机厂商Push推送；</p>
								<p>&nbsp;&nbsp;2) 用于在您同意的情况下获取设备位置权限、搜集设备信息和日志信息等；</p>
								<p>&nbsp;&nbsp;3) 用于第三方授权服务，将有关内容分享至第三方产品等；</p>
								<p>&nbsp;&nbsp;4) 用于账号安全、产品加固相关服务，包括数据加密解密服务等。</p>
								<p>该等第三方服务由相关的第三方负责运营；您使用其服务或向其提供信息，须受该等第三方的服务条款及/或隐私保护声明（而非本政策）约束。</p>
								<p>
									<b>(七)</b>
									<b>未成年人的隐私</b>
								</p>
								<p>
									我们不会向任何未满14周岁（或相关司法辖区规定的类似最低年龄）的未成年人提供任何华蚁人力服务。如果我们发现任一账户与未满14周岁（或相关司法辖区规定的类似最低年龄）的未成年人有关联或由其注册，我们会立即删除相关的账户信息。如果我们发现自己在未事先获得可证实的父母同意的情况下收集了未成年人的个人信息，会设法尽快删除相关数据。
								</p>
								<p>
									如果您是未满14周岁（或相关司法辖区规定的类似最低年龄）的未成年人父母或监护人，且认为您的未成年人向我们披露了她/他的个人信息，请立即通过所在地的华蚁人力支持信息上的电话或邮件联系我们。未满14周岁（或相关司法辖区规定的类似最低年龄）的未成年人父母或其监护人可以检查和要求删除其未成年人的个人信息并禁止我们使用。对于经父母/监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。
								</p>
								<p>
									<b>(八)</b>
									<b>联系我们</b>
								</p>
								<p>华蚁人力资源服务（天津）有限公司，注册地址位于天津经济技术开发区滨海-中关村科技园融汇商务园四区9号楼5L21。</p>
								<p>如果您对本隐私政策或您的个人信息有任何的疑问、意见或建议，您可以通过以下方式与我们联系：</p>
								<p>• 电子邮件：longqianzhang163@163.com</p>
								<p>• 联系电话：15522159984</p>
								<p>我们将在审核并验证您的身份后的十五个工作日内处理。</p>
								<p>
									<b>(九)</b>
									<b>隐私政策修订</b>
								</p>
								<p>
									我们可能对本隐私政策进行不定期修订，该等修订构成本隐私政策的一部分。当政策的条款发生变更时，我们会在您登录及版本更新时以推送通知、弹窗的形式向您展示变更后的政策。请您注意，只有在您点击弹窗中的同意按钮后，我们才会按照更新后的政策收集、使用、存储您的个人信息。
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script></script>

<style type="text/css">
.table-plan {
	margin: 0 auto;
	border-collapse: collapse;
}

.table-plan caption {
	padding-bottom: 10px;
	font-size: 18px;
	line-height: 30px;
	text-align: center;
	color: #333;
}

.table-plan td,
.table-plan th {
	padding: 3px 5px;
	line-height: 24px;
	border: 1px solid #ccc;
	text-align: center;
}

.table-plan th {
	background-color: #f2f2f2;
}

.table-plan td.tip {
	text-align: left;
}

.table-plan td.noborder {
	text-align: left;
	border: none;
}
</style>
