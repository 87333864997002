<template>
	<!-- 咨询联系 -->
	<div class="wrapper-main">
		<div class="app-section__contact">
			<div class="contact">
				<div class="inner">
					<div class="content">
						<div class="text">
							如有其他需求请联系华蚁人力机械
						</div>
					</div>
					<div class="codes">
						<div class="code" style="display: flex; flex-direction: column; align-items: start">
							<div class="text" style="font-size: 25px">邮箱： longqianzhang163@163.com</div>
							<div class="text" style="font-size: 25px">电话： 15522159984</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- 咨询联系 -->
</template>

<script></script>

<style></style>
