<template>
	<div class="app-body">
		<div class="app-banner__page serve sticky">
			<div class="app-banner__page-inner" style="background-color: rgba(0, 0, 0, 0.35)">
				<div class="app-container">
					<div>
						<h1 style="font-weight: 900; color: #fff; font-size: 72px">华蚁人力机械信息服务</h1>
					</div>
				</div>
			</div>
			<div class="app-banner__page-module" style="background-color: rgba(0, 0, 0, 0.35)">
				<div class="app-container">
					<div class="modules">
						<a class="item" href="javascript:">
							<div style="text-align: center">
								<div class="icon icon-3">
									<span class="icon iconfont" style="font-size: 42px">&#xe758;</span>
								</div>
								<h3 class="cls-1">信息简洁</h3>
							</div>
						</a>
						<a class="item" href="javascript:">
							<div style="text-align: center">
								<div class="icon icon-5">
									<span class="icon iconfont cls-1" style="font-size: 42px">&#xe63e;</span>
								</div>
								<h3 class="cls-1">用户认证</h3>
							</div>
						</a>
						<a class="item" href="javascript:">
							<div style="text-align: center">
								<div class="icon icon-6">
									<span class="icon iconfont" style="font-size: 42px">&#xe64c;</span>
								</div>
								<h3 class="cls-1">售后服务</h3>
							</div>
						</a>
						<a class="item" href="javascript:">
							<div style="text-align: center">
								<div class="icon icon-7">
									<span class="icon iconfont" style="font-size: 42px">&#xe63f;</span>
								</div>
								<h3 class="cls-1">资质认证</h3>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>

		<div id="submenu-sticky">
			<!-- tab -->
			<div class="app-submenu hide">
				<div class="app-container">
					<div class="app-submenu-wrap">
						<ul class="app-submenu-menu">
							<li class="active" data-submenu="#jxzl">
								<a href="#jxzl" @click.prevent="scrollToSection('jxzl')">
									<h2>机械租赁</h2>
								</a>
							</li>
							<li data-submenu="#jxzr">
								<a href="#jxzr" @click.prevent="scrollToSection('jxzr')">
									<h2>机械转让</h2>
								</a>
							</li>
							<li data-submenu="#jxwx">
								<a href="#jxwx" @click.prevent="scrollToSection('jxwx')">
									<h2>机械维修</h2>
								</a>
							</li>
							<!-- <li data-submenu="#gdxx">
								<a href="#gdxx" @click.prevent="scrollToSection('gdxx')">
									<h2>工地信息</h2>
								</a>
							</li>
							<li data-submenu="#jxxx">
								<a href="#jxxx" @click.prevent="scrollToSection('jxxx')">
									<h2>机械信息</h2>
								</a>
							</li>
							<li data-submenu="#column-case">
								<h2>找活信息</h2>
							</li> -->
						</ul>
					</div>
				</div>
			</div>

			<div class="content" ref="content" id="#content">
				<!-- 机械租赁 -->
				<div class="wrapper-pattern-1" id="jxzl">
					<div class="app-container">
						<div class="wrapper-content">
							<div class="app-common-title">
								<h2>机械租赁</h2>
							</div>
							<div class="wrapper-content app-serve-type jx-box">
								<div class="app-serve-type-item" v-for="(jxitem, jxindex) in jxzldata" :key="jxitem.id">
									<div class="app-serve-type-mudule">
										<div class="img-box">
											<img class="jx-img" :src="jxitem.image" />
										</div>
										<h4 class="title">{{ jxitem.title }}</h4>
										<p class="intor">价格：{{ jxitem.price }}</p>
										<p class="intor">{{ jxitem.intro }}</p>

										<div class="buttons">
											<router-link :to="`/index/jxzl/id=${jxitem.id}`" class="app-common-more blue">查看信息详情</router-link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- 机械转让 -->
				<div class="wrapper-grey" id="jxzr">
					<div class="app-container">
						<div class="wrapper-content">
							<div class="app-common-title">
								<h2>机械转让</h2>
							</div>
							<div class="app-serve-hot">
								<div class="contents">
									<div class="content show">
										<div class="wrapper-content">
											<div class="item" v-for="zritem in jxzrdata" :key="zritem.id">
												<div style="width: 60%">
													<h3 class="title">{{ zritem.title }}</h3>
													<!-- <br />
													<span class="size">
														<span>联系方式：</span>
														<span>{{ zritem.mode }}</span>
													</span> -->
												</div>
												<div style="width: 20%">
													<div class="date" style="width: 100%; margin-bottom: 10px">
														<span>价格：</span>
														<span>{{ zritem.price }}</span>
													</div>
													<router-link :to="`/index/jxzr/id=${zritem.id}`" class="blue">查看信息详情</router-link>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- 工地信息 -->
				<!-- <div class="wrapper-bright" id="gdxx">
					<div class="app-container">
						<div class="wrapper-content">
							<div class="app-common-title">
								<h2>工地信息</h2>
							</div>
							<div class="wrapper-content no-bottom">
								<div class="app-section__news-main" style="flex-wrap: wrap">
									<div class="app-section__news-main__cell" v-for="gditem in gdxxdata" :key="gditem.id">
										<router-link :to="`/index/gdxx/id=${gditem.id}`">
											<div class="inner">
												<div class="single">
													<div class="image">
														<img style="width: 100%; height: 320px" :alt="gditem.title" :src="gditem.image" />
													</div>
													<div class="content">
														<div class="head">
															<div class="top"></div>
															<h4 class="title">{{ gditem.title }}</h4>
															<div class="intor">
																{{ gditem.intro }}
															</div>
														</div>
													</div>
												</div>
											</div>
										</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> -->

				<!-- 机械信息 -->
				<!-- <div class="wrapper-pattern-1" id="jxxx">
					<div class="app-container">
						<div class="wrapper-content">
							<div class="app-common-title">
								<h2>机械信息</h2>
							</div>
							<div class="wrapper-content app-serve-type jx-box">
								<div class="app-serve-type-item" v-for="(jxitem, jxindex) in jxxxdata" :key="jxitem.id">
									<div class="app-serve-type-mudule">
										<div class="img-box">
											<img class="jx-img" :src="jxitem.image" />
										</div>
										<h4 class="title">{{ jxitem.title }}</h4>
										<p class="intor">{{ jxitem.intro }}</p>
										<div class="buttons">
											<router-link :to="`/index/jxxx/id=${jxitem.id}`" class="app-common-more blue">查看信息详情</router-link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> -->

				<!-- 机械维修 -->
				<div class="wrapper-pattern-1" id="jxwx">
					<div class="app-container">
						<div class="wrapper-content">
							<div class="app-common-title">
								<h2>机械维修信息</h2>
							</div>
							<div class="wrapper-content app-serve-type jx-box">
								<div class="app-serve-type-item" v-for="(jxitem, jxindex) in jxwxdata" :key="jxitem.id">
									<div class="app-serve-type-mudule">
										<div class="img-box">
											<img class="jx-img" :src="jxitem.image" />
										</div>
										<h4 class="title">{{ jxitem.title }}</h4>
										<p class="intor">价格：{{ jxitem.price }}</p>
										<p class="intor">{{ jxitem.intro }}</p>
										<div class="buttons">
											<router-link :to="`/index/jxwx/id=${jxitem.id}`" class="app-common-more blue">查看信息详情</router-link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 找活信息 -->
			<!-- <div class="wrapper-grey" id="zhxx">
				<div class="app-container">
					<div class="wrapper-content">
						<div class="app-common-title">
							<h2>找活信息</h2>
						</div>
						<div class="app-serve-hot">
							<div class="contents">
								<div class="content show">
									<div class="wrapper-content">
										<div class="item">
											<div>
												<h3 class="title">黔东南剑河县黔东南苗族侗族自治州剑河县招架桥机司机</h3>
												<span class="size">黔东南苗族侗族自治州剑河县招架桥机司机两名，B2证，开九米六特种作业车，普工两名，公司直招联系人胡先生</span>
											</div>
											<span class="date">2024-05-10 14:16</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> -->

			<!-- 咨询联系 -->
			<footzxVue></footzxVue>
		</div>
	</div>
</template>

<script setup>
import footzxVue from '@/components/foot-zx/foot_zx.vue';

import { ref, onMounted, onUnmounted } from 'vue';

// 机械租赁信息
import { JXZLDataJS } from '@/common/utils/jxzldata';
const jxzldata = JXZLDataJS();

// 机械信息
import { JXXXDataJS } from '@/common/utils/jxxxdata';
const jxxxdata = JXXXDataJS();

// 机械转让
import { JXZRDataJS } from '@/common/utils/jxzrdata';
const jxzrdata = JXZRDataJS();

// 工地信息
import { GDXXDataJS } from '@/common/utils/gdxxdata';
const gdxxdata = GDXXDataJS();

// 机械维修
import { JXWXDataJS } from '@/common/utils/jxwxdata';
const jxwxdata = JXWXDataJS();

console.log('jxzldata', jxzldata);
console.log('jxxxdata', jxxxdata);
console.log('jxzrdata', jxzrdata);
console.log('gdxxdata', gdxxdata);

// tab
// const content = ref(null);
// let isFixed = ref(false);
// let lastScrollTop = ref(0);

// const handleScroll = () => {
// 	// 滚动距离
// 	const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
// 	// tab距离
// 	const content = document.getElementById('#content');
// 	// const offsetTop = content.value.getBoundingClientRect().top + 65;
// 	const offsetTop = content.getBoundingClientRect().top + 177;
// 	console.log(scrollTop, offsetTop);

// 	// 滚动距离大于Tab栏距离顶部的距离时，固定Tab栏
// 	if (scrollTop > offsetTop) {
// 		if (!isFixed) {
// 			isFixed = true;
// 		}
// 	} else {
// 		// 如果向上滚动且Tab栏已经固定，则取消固定
// 		if (isFixed && scrollTop < lastScrollTop) {
// 			isFixed = false;
// 		}
// 	}

// 	lastScrollTop = scrollTop;
// };

// onMounted(() => {
// 	window.addEventListener('scroll', handleScroll);
// });

// onUnmounted(() => {
// 	window.removeEventListener('scroll', handleScroll);
// });

// 锚点跳转
const scrollToSection = (id) => {
	const element = document.getElementById(id);
	if (element) {
		element.scrollIntoView({ behavior: 'smooth' }); // 平滑滚动
		// element.className = 'active';
		// console.log(element);
	}
};
</script>

<style>
.jx-box {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 20px;
}

.jx-img {
	width: 200px;
	border-radius: 17px;
	height: 133px;
}

.intor {
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	/* 这个为三行溢出显示省略号 */
	-webkit-line-clamp: 2;
	overflow: hidden;
}

.title {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	margin: 10px 0 0 0;
}

.app-serve-type-mudule {
	display: flex;
	align-items: center;
	justify-content: center;
}

.app-common-title {
	margin-top: 25px;
}

.app-submenu {
	transition: top 0.3s ease;
}

.fixed {
	position: fixed;
	top: 65px;
	width: 100%; /* 根据需要设置 */
	z-index: 9999999; /* 确保Tab栏在内容之上 */
}
</style>
