export function JXZLDataJS() {
	const jxdata = [
		// zl01
		{
			id: 'zl01',
			title: '天津机床设备搬迁叉车吊车出租',
			intro: '天津机床设备吊装搬运上楼·起重搬运 叉车吊车出租 设备搬迁 机床设备搬运 吊装搬运吊装 大型设备吊装',
			image: ['https://pic6.58cdn.com.cn/p1/big/n_v2c4520c3be13d40ccb0c7dc0a55429230.jpg?w=800&h=500'],
			price: '4500元/天起',
			innerIntroTitle: '基本信息',
			innerIntro: [{
					title: '类别',
					text: '机械设备租赁'
				},
				{
					title: '服务区域',
					text: '塘沽  武清  东丽  西青  北辰  津南  宁河  芦台镇  宁河镇  潘庄镇  滨海新区'
				},
				{
					title: '联系人',
					text: '陈先生'
				},
				{
					title: '联系电话',
					text: '17695544611'
				},
				{
					title: '商家地址',
					text: '静海 -  陈官屯镇  - 陈官屯镇'
				}
			],
			content: '汽车起重机、工程机械、机械出租 设备搬运移位 重型设备搬运吊装 设备吊装安装搬运 专业设备搬运 设备搬运 设备吊装搬运 折臂吊 折臂吊起重吊装 折臂吊 起重吊装 折臂吊起重吊装 起重吊装搬运 吊车吊装起重机 起重吊装工 吊装起重工 起重吊装 起重吊装 吊装起重机设备吊装 设备搬迁安装设备吊装 吊装设备 设备吊装安装搬运 设备搬运吊装 重型设备搬运吊装 设备吊装 设备搬运 吊装设备安装 设备吊装平台 折臂吊 设备吊装搬运 机械设备吊装 大型设备吊装 吊装设备 设备 设备调试安装 设备搬运位移 设备搬运吊装 装卸装卸上门服务 装卸搬运 机床搬运 设备起重搬运 机床起重搬运 设备搬迁 设备搬运搬厂 设备搬运吊装车床冲床数控龙门铣加工中心液压机压力机镗床数控机床起重搬运吊装组吊装 制冷机搬运各种机床设备搬运吊装安装。',
			introduce: '天津众信机床设备吊装搬运是一家专业承接工业搬迁，设备搬运业务。根据用户需要，选择你所需要的设备，车辆各行配备专业司机，专业装卸等搬运工作人员，安全快捷，可一条龙完成您所需要的工作。天津众信机床设备吊装搬运，专业承接工业搬迁，设备搬运业务，车床，冲床，数控龙门铣 加工中心 液压机 压力机 镗床 叉车卸 数控机床起重搬运吊装 制冷机搬运 各种机床设备搬运吊装安装。'
		},
		// zl02
		{
			id: 'zl02',
			title: '宸锟叉车起重搬运·天津出租',
			intro: '宸锟叉车起重搬运·天津出租⭐设备 叉车租 救援车⭐拖车⭐叉车起重3-16吨叉车 叉车装卸。',
			image: ['https://pic1.58cdn.com.cn/lbghy/hypost/n_v3eea041ba1fd04dc38496e8d29c1b09f9.jpg?w=338&h=253'],
			price: '3.5万/月起',
			innerIntroTitle: '基本信息',
			innerIntro: [{
					title: '类别',
					text: '机械设备租赁'
				},
				{
					title: '小类',
					text: '动力设备租赁'
				},
				{
					title: '服务区域',
					text: '河西  西青  南开  和平  红桥  河东  河北'
				},
				{
					title: '联 系 人',
					text: '鲁经理'
				},
				{
					title: '联系电话',
					text: '17694824099'
				},
				{
					title: '商家地址',
					text: '红桥 -  双环邨街  - 天津市红桥区双环邨街双环路51-53号'
				}
			],
			content: '天津租⭐设备 叉车服务 救援车⭐拖车⭐叉车起重3-16吨叉车 叉车装卸包天包月 专业团队一条龙服务。叉车包天包月 起重包天包月。就近派车，随叫随到，24小时服务！望新老客户给予大力的支持和帮助！我们会热情的为您提供满意的服务！一、长租：以月为租赁结算。我们仅提供叉车和叉车正常使用所需的维修保养，其余的由承租方负责。二、包业务制：我们提供叉车、司机、燃油、叉车的维修和保养等。以月为结算。不需多次寻找供应商及谈价。三、项目承包：对仓储项目、物流项目进行总承包，由我们提供项目负责人、叉车、司机、燃油、搬运人员等。',
			introduce: '天津博爱叉车拖车一直秉承这个理念，以强大的技术支持和良好的服务作后盾，从小事做起，从细节抓起，一步一个脚印，实现了与客户的双赢和共同发展。叉车全天津就近派车！！我们秉承专业、诚信、用心的理念服务广大客户，致力提供各种类型的叉车，以满足不同领域的客户需求。我们本着为顾客着想，解顾客之忧的服务精神，在市场中不断发展壮大。我们会从您的角度为您考虑到每一个细小的环节，以大限度满足您的需要。​'
		},
		// zl03
		{
			id: 'zl03',
			title: '天津佳和吊车随车吊租赁',
			intro: '天津佳和吊车随车吊租赁·吊车随车吊出租、机械设备吊装、钢结构安装',
			image: ['https://pic2.58cdn.com.cn/p1/big/n_v2e000af48ba154383b91f6fea73c05312.jpg?w=338&h=253',
				'https://pic2.58cdn.com.cn/p1/big/n_v2504bf0a9c2d04b15851fb2f422551f51.jpg?w=338&h=253',
				'https://pic7.58cdn.com.cn/p1/big/n_v249cc69ecfe244a6b9a2af4a66583412a.jpg?w=338&h=253',
				'https://pic7.58cdn.com.cn/p1/big/n_v273efcc6b95a34db9bd323aa74ff13c55.jpg?w=338&h=253'
			],
			price: '9.5万/月起',
			innerIntroTitle: '重要参数',
			innerIntro: [{
					title: '类别',
					text: '机械设备租赁'
				},
				{
					title: '小类',
					text: '起重吊装租赁'
				},
				{
					title: '服务区域',
					text: '东丽  西青  南开  塘沽  津南  河东  北辰  河北  红桥  和平'
				},
				{
					title: '联 系 人',
					text: '谢师傅'
				},
				{
					title: '联系电话',
					text: '17695960447'
				},
				{
					title: '商家地址',
					text: '东丽 -  张贵庄街  - 张贵庄街道'
				}
			],
			content: '该商家可提供大型网架结构整体吊装、机械设备吊装、钢结构安装等服务。天津佳和吊车随车吊租赁是一家合法正规、专业的从事工厂搬迁、装卸货柜、机器进场安装，专业搬场，移位，难度装卸，重型起重、高空吊装、设备上下楼、为主营业务，随车起重运输，它广泛用于市政建设，煤矿工程，园林绿化等基建材料以及运输,优良的设备和专业的服务团队。专业从事8吨至20吨随车吊出租，天津全范围24小时热情服务，！',
			introduce: '随车吊适用于：建筑工地、热力管道、园林绿化、设备搬迁、广告安装等等。自装自卸，方便快捷。成都恒动随车吊，价格低、实力强，欢迎广大客户竞相合作。价格低、实力强，欢迎广大客户竞相合作。以客户需求为发展的根本，保障服务质量，不断提高发展技术和服务领域，为客户提供安全、优质、经济、方便的服务。随车吊的好处：1.提高设备搬运的效率，节省人力成本，减轻装卸工人的劳动强度，改善了工人的劳动条件。2.提高设备搬运作业的质量，保证货物免受损害，降低了设备搬运的作业成本。3.缩短设备搬运的时间，加速运输的周转率，同时加速货位的周转。专业承接：设备起重、迁移、就位、工厂搬迁、货运、吊装、设备安装、装车卸车等服务！1、承接各行各业的厂房吊装、机械设备搬迁移位、精密设备上下楼、重型机器移位、厂房设备搬迁、大型设备吊装、就位校正、大件设备运输、超市广告牌吊装、地基基础、道路桥涵、设备就位；、2、超高层建筑的钢结构、标志、机组的吊装；3、预制桩驳输，桩机搬场，塔吊拆装。4、厂房拆除、机器设备搬场；集装箱掏箱、装箱；压机、中央空调、电梯、大罐的吊卸、装卸方桩管桩、盘路就位；5、钢结构安装；所有桥式类型起重机的拆、安、检；6、大型网架结构整体吊装；工业、民用和安装工程， 园林建设以及运输工程中的吊装工作。'
		},
		// zl04
		{
			id: 'zl04',
			title: '天津鸿明叉车租赁',
			intro: '天津鸿明叉车租赁·叉车出租叉车租赁租赁日租月租年租有优惠叉车维修叉车回收设备搬迁。	',
			image: ['https://pic8.58cdn.com.cn/lbghy/hypost/n_v3f63b6f53ead54491a0c0c81d1f5957f9.jpg?w=338&h=253'],
			price: '2500元/天起',
			innerIntroTitle: '基本信息',
			innerIntro: [{
					title: '类别',
					text: '机械设备租赁'
				},
				{
					title: '小类',
					text: '起重吊装租赁'
				},
				{
					title: '服务区域',
					text: '西青  南开  河东  河西  东丽  河北  北辰  红桥  和平  津南'
				},
				{
					title: '联 系 人',
					text: '李经理'
				},
				{
					title: '联系电话',
					text: '17694809047'
				},
				{
					title: '商家地址',
					text: '西青 -  大寺  - 大寺镇'
				}
			],
			content: '专业承接：叉车出租租赁（时租 天租 月租）叉车出租租赁（散租 短租 长租）1、 工业搬迁、 仓库搬迁、 大、 中、 小型搬迁、 货物运输、 货物装卸等服务项目。2、 专业从事叉车出租、 叉车租赁、 3 - 16 吨叉车出租、 清障车救援、 起重吊装、 设备搬运等。',
			introduce: '天津鸿明叉车租赁出租叉车出租，24小时服务，严谨诚信 、敬业创新"是我们的理念,"客户至上、品质至上"是我们的宗旨，"以人为本"是我们的信念，让选择我们的客户舒心、放心、安心是我们的目标。 经营理念：诚信经营服务优先 质量理念：高标准、精细化，追求零缺陷；用户的需求，就是我们的追求。1、人员优势：高效--操作人员全部都有着丰富的技术经验，能配合工人更快更高效的完成工作，为您节省时间和资金成本。2、设备优势：安全--我们设备都经过专人检车和维护，为工程安全顺利的完成保驾护航。3、服务优势：免费为您设计作业方案，我们更懂您的需求！'
		},
		// zl05
		{
			id: 'zl05',
			title: '天津卓越机械设备租赁',
			intro: '天津卓越机械设备租赁·附近3-25吨叉车出租，叉车租赁叉车装卸吊车出租升降车租赁拖车出租搬运',
			image: ['https://pic7.58cdn.com.cn/lbghy/hypost/n_v3980582a7440a467ab60005bf952900f0.jpg?w=338&h=253',
				'https://pic6.58cdn.com.cn/lbghy/hypost/n_v374e13da44e664b2dab8bb064d07b7445.png?w=338&h=253',
				'https://pic3.58cdn.com.cn/lbghy/hypost/n_v35ecfe7b8a0e945ca8eeaf3072ee7f534.jpg?w=338&h=253'
			],
			price: '4万/月起',
			innerIntroTitle: '基本信息',
			innerIntro: [{
					title: '类       别',
					text: '机械设备租赁'
				},
				{
					title: '小       类',
					text: '起重吊装租赁'
				},
				{
					title: '服务区域',
					text: '西青  塘沽  南开  津南  武清  河东  河西  东丽  河北  北辰'
				},
				{
					title: '联 系 人',
					text: '张经理'
				},
				{
					title: '联系电话',
					text: '17602634825'
				},
				{
					title: '商家地址',
					text: '西青 -  大寺  - 大寺村'
				}
			],
			content: '叉车出租，救援车出租、吊车租赁、升降车出租等机械设备租赁：现有叉车3吨，3.5吨叉车，5吨叉车，6吨叉车，7吨叉车，8吨叉车，10吨叉车，12吨叉车，15吨叉车，16吨叉车，20吨叉车，25吨叉车。价格优，可按年租、月租、天租等各种方式灵活出租，24小时服务，可开发票；拥有十几年的吊装经验有一批资深工作经验的吊车师傅，有些丰富的工作经验。并具有特种起重设备的各种工具，可为各种场地设备的安装就位服务。我们是一家汇集移位，起重、吊装、安装为一体的专业综合服务租车队，根据用户需要，选择你所需要的设备，车辆各行配备专业师傅，专业装卸等搬运工作人员，安全快捷，可一条龙完成您所需要的工作。【业务范围】 1、专业搬厂，大型工厂设备物资转运，搬工地、厂房安装与拆除,货柜装卸，重型机械吊装，设备上下楼,空调主机下地下室。2、设备吊装移位定位、塔吊装卸，桩机转移转运，机器设备吊装与搬迁。3、重型设备吊装、精密设备吊装、电厂设备吊装、钢结构吊装、化工厂设备吊装、大型设备吊装运输。4、钢结构的吊装安装、拆卸、运输；大罐的吊装装卸、盘路就位，精密吊装。5、广告牌吊装、电梯安装与拆卸、大小型设备、货物装卸与吊装运输。',
			introduce: '服务项目有：大件重件吊装，钢结构厂房吊装，路桥市政工程吊装，塔吊装卸，桩机转运，各种大小货物装卸，高层楼房设备起重安装，厂内（车间） 设备起重、移位、安装。 技术娴熟，经验丰富，，热情的服务，愿新老客户长期合作！服务流程： 1、拨打我们的24小时服务，说明您的需求。 2、我们即时给出报价，如果作业情况复杂我们会立刻免费上门勘察然后给出准确合理报价。 3、记录下您的特殊要求、注意事项，然后签订合同。 4、我们按照您的约定时间按时保质保量完成服务。 5、完成后，客户检查验收，没有问题后付全款，如有后续问题可直接联系。选择我们的理由： 1、安全可靠、让您放心，正规性、专业性无可比拟。 2、服务电话24小时接听，全年无休，随叫随到，急客户之所急。 3、一次性报价，承诺中途绝不加价，解决客户后顾之忧。 4、合理收费，承诺价格始终在中等偏下.我们始终坚守： 价格要合理、 质量是关键【 租赁方式】 小时租、 日租、 台班、 月租等。 欢迎致电咨询， 全天24小时等您调度。 '
		},
		// zl06
		{
			id: 'zl06',
			title: '天津市叉车拖车吊车·叉车出租',
			intro: '天津市叉车拖车吊车出租·叉车出租 叉车租赁 吊车出租 吊车租赁 道路救援',
			image: [
				'https://pic4.58cdn.com.cn/nowater/lbghy/sjt/pic/n_v373e61236b08c4ba2a01370d760bd2bdb.jpg?w=800&h=500',
				'https://pic7.58cdn.com.cn/nowater/lbghy/sjt/pic/n_v3176d3bbef9b845319d825f3640887668.jpg?w=800&h=500',
				'https://pic3.58cdn.com.cn/nowater/lbghy/sjt/pic/n_v3ba3ff37080d94cf2a7dc130252f31ca2.jpg?w=800&h=500',
				'https://pic4.58cdn.com.cn/nowater/lbghy/sjt/pic/n_v3a73802bfdf704d479211346cc57dd886.jpg?w=800&h=500',
				'https://pic5.58cdn.com.cn/nowater/lbghy/sjt/pic/n_v343a396f29dbf46b58cf8e22842dd9792.jpg?w=800&h=500',
				'https://pic2.58cdn.com.cn/nowater/lbghy/sjt/pic/n_v33fcbf126e0f24455a446956cb06d322e.jpg?w=800&h=500',
				'https://pic3.58cdn.com.cn/nowater/lbghy/sjt/pic/n_v36419fa3deb0f48ca8165bb642691f88a.jpg?w=800&h=500'
			],
			price: '4.2万/月起',
			innerIntroTitle: '基本信息',
			innerIntro: [{
					title: '类       别',
					text: '机械设备租赁'
				},
				{
					title: '小       类',
					text: '起重吊装租赁'
				},
				{
					title: '服务区域',
					text: '东丽  河东  河西  津南  红桥  开发区  和平  大港  汉沽  宁河'
				},
				{
					title: '联 系 人',
					text: '孔经理'
				},
				{
					title: '联系电话',
					text: '17695584426'
				},
				{
					title: '商家地址',
					text: '东丽 -  华明镇  - 顶秀欣园社区居民委员会'
				}
			],
			content: '专业承接叉车出租、租赁，机械设备租赁，现有叉车，3.5吨叉车，5吨叉车，6吨叉车，7吨叉车，8吨叉车，10吨叉车，13叉车，15吨叉车，16吨叉车，20吨叉车，25吨叉车，可按年租、月租、天租等各种方式灵活出租，24小时服务，可开发票；专业吊车租赁、起重吊装、设备就位，汽车起重机出租的大型机械设备租赁的，目前拥有小型吊车8吨～13吨～25吨50吨！中型80吨～130吨～160吨！大型200吨～240吨～300吨～500吨吊车，拥有十几年的吊装经验有一批资深工作经验的吊车师傅，对各种高，难，重，险的吊装工程有些丰富的工作经验。并具有特种起重设备的各种工具，可为各种场地设备的安装就位服务。根据客户要求，就近安排车辆。叉车配有专车运输，保证方便快捷到达客户地点。',
			introduce: '【业务范围】1、工地、学校、仓库搬迁、大、中、小型搬迁、货物运输、货物装卸等服务项目。2、专业从事叉车出租、叉车租赁、叉车出租、清障车救援、起重吊装、设备搬运等。3、钢构吊装，设备移位，就位，树木移栽，事故车吊装，广告牌吊装，混泥土，砖块，钢筋，模板，一切吊装服务。【我们的优势】1、安全保证、正规、专业让您放心。2、服务电话24小时接听、全年无休、随叫随到、急客户之所急。3、一次性报价、承诺中途绝不加价、解决客户后顾之忧。4、合理收费、价格要合理、质量是关键。'
		},
		// zl07
		{
			id: 'zl07',
			title: '天津奕成机械设备租赁',
			intro: '天津奕成机械设备租赁·专业挖掘机出租 破碎锤出租 室内外拆除 路面破碎 24小时服务签订合同',
			image: ['https://pic1.58cdn.com.cn/lbghy/hypost/n_v338ba2e630c034a588702a512cd210cf0.jpg?w=338&h=253',
				'https://pic2.58cdn.com.cn/lbghy/hypost/n_v3aff2f8927ba746d9a5d446bb2dce6174.jpg?w=338&h=253',
				'https://pic2.58cdn.com.cn/lbghy/hypost/n_v3daee3b609260456ab799230c33e6d834.jpg?w=338&h=253',
				'https://pic6.58cdn.com.cn/lbghy/hypost/n_v3a61e895deaca4ecfbde0f3f352fa6dc9.jpg?w=338&h=253',
				'https://pic5.58cdn.com.cn/lbghy/hypost/n_v3f10fcbc840f74d3691efde4977960165.png?w=338&h=253',
				'https://pic1.58cdn.com.cn/lbghy/hypost/n_v3ce59bd9184ef425e8d05979878fcd1f7.png?w=338&h=253'
			],
			price: '3000元/天起',
			innerIntroTitle: '基本信息',
			innerIntro: [{
					title: '类       别',
					text: '机械设备租赁'
				},
				{
					title: '小       类',
					text: '路面机械租赁'
				},
				{
					title: '服务区域',
					text: '西青  塘沽  南开  津南  武清  河东  河西  东丽  河北  北辰'
				},
				{
					title: '联 系 人',
					text: '高经理'
				},
				{
					title: '联系电话',
					text: '17694834082'
				},
				{
					title: '商家地址',
					text: '西青 -  精武  - 精武镇'
				}
			],
			content: '拥有 挖掘机出租 推土机出租 铲车出租 救援车出租 运行车出租 叉车出租，挖掘机 型号：10 15 20 30 60 70 120 200 350 490 等各种 破碎锤 加长臂 伸缩臂，推土机 型号： 80 100 160 180 等各种大中小型设备，装载机 型号： 山猫70 20 30 50 650 等各种大中小型设备，承接各种： 建筑物拆除 室内拆除 土方开槽 基础回填 管道开挖 路面破碎 园林绿化 各种大小工程，短租 长租 年租 月租 日租 小时租 可开发票 24 小时 签订合同 机械出租',
			introduce: '【热门服务】该商家可提供推土机、叉车出租、加长臂、园林绿化/养护、挖掘机出租等服务。【热门服务】可提供推土机、叉车出租、加长臂、园林绿化/养护、挖掘机出租等服务。'
		},
		// zl08
		{
			id: 'zl08',
			title: '天津市老六工程机械租赁',
			intro: '天津市老六工程机械租赁·挖掘机租赁、出租挖掘机，微小型挖掘机！挖机破碎锤，拆除改造',
			image: [
				'https://pic1.58cdn.com.cn/nowater/lbghy/sjt/pic/n_v2f10aa4c9040e46218a21c98396899e87.jpg?w=800&h=500',
				'https://pic2.58cdn.com.cn/nowater/lbghy/sjt/pic/n_v21a396440bcb8431b8901613fd1a00c9a.jpg?w=800&h=500',
				'https://pic2.58cdn.com.cn/nowater/lbghy/sjt/pic/n_v2a222656a4b2b442cba773ba0ee0cc93c.jpg?w=800&h=500',
				'https://pic6.58cdn.com.cn/nowater/lbghy/sjt/pic/n_v3df2430261c724fefa79ddef19a463edc.jpg?w=800&h=500',
				'https://pic7.58cdn.com.cn/nowater/lbghy/sjt/pic/n_v306670a78e836490cade65097a1c4759b.jpg?w=800&h=500',
				'https://pic2.58cdn.com.cn/nowater/lbghy/sjt/pic/n_v3a0cc68624d9744f7a32399423c457de1.jpg?w=800&h=500'
			],
			price: '2500元/天起',
			innerIntroTitle: '基本信息',
			innerIntro: [{
					title: '类       别',
					text: '机械设备租赁'
				},
				{
					title: '服务区域',
					text: '红桥  北辰  河东  河西  津南  南开  西青  东丽  武清  河北'
				},
				{
					title: '联 系 人',
					text: '马经理'
				},
				{
					title: '联系电话',
					text: '17694883594'
				},
				{
					title: '商家地址',
					text: '北辰 -  北辰周边  - 北辰道[地铁站]'
				}
			],
			content: '专业出租挖掘机、大、中、小、微型挖掘机租赁、 破碎锤 拆除租赁。挖掘机出租，各种机械设备租赁，15，20，30型，60，70型，200型挖掘机10余台出租，专业承接，房屋拆除拆除改造，专业破碎锤，地面破碎，楼梯拆除，上下水改造，别墅扩建挖地下室，园林绿化，挖各种水管，电缆沟管道，清槽底，基坑底，各种狭窄地方施工作业，代替人工，省时省力',
			introduce: '【业务范围】土方开挖、废土外运、基础回填、厂房改建、房屋拆除、混凝土路面破碎、道路修造、场地整平、园林绿化、管道开挖铺设、等工程施工服务为一体。多年来我们以优质的工程施工完成了多个工程项目。【优势】1、安全保证、让您放心，正规性、专业性无可比拟。2、服务电话24小时接听，全年无休，随叫随到，急客户之所急。3、合理收费，承诺价格始终在中等偏下，我们始终坚守：价格要合理、质量是关键。4、客户可以定期、不定期或短时租用，努力为用户节省开支、让客户无后顾之忧！'
		}
	]
	return jxdata;
}