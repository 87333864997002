import {
	createRouter,
	createWebHashHistory
} from 'vue-router';


import indexVue from '@/pages/index/indexVue.vue'; // 首页

import jxzl from '@/pages/index/jxzl/jxzl.vue'; // 机械租赁信息
import jxxx from '@/pages/index/jxxx/jxxx.vue'; // 机械信息
import jxzr from '@/pages/index/jxzr/jxzr.vue'; // 机械转让
import gdxx from '@/pages/index/gdxx/gdxx.vue'; // 工地信息
import jxwx from '@/pages/index/jxwx/jxwx.vue'; // 机械维修


import userInfo from "@/pages/UserInfo/userVue.vue"; // 个人中心
import SetUp from "@/pages/setup/SetUp.vue"; // 信息发布
import Privacy from "@/pages/privacy/PrivacyPolicy.vue"; // 隐私政策

import Login from "@/pages/login/LoginUser.vue"; // 登录
import Register from "@/pages/register/RregisterUser.vue"; // 注册


//路由表映射
export const routes = [{
		path: '',
		redirect: '/index',
	},
	{
		// 首页
		name: 'index',
		path: '/index',
		component: indexVue,
	},
	{
		// 机械租赁信息
		name: 'jxzl',
		path: '/index/jxzl/:id',
		component: jxzl,
	},
	{
		// 机械信息
		name: 'jxxx',
		path: '/index/jxxx/:id',
		component: jxxx,
	},
	{
		// 机械转让
		name: 'jxzr',
		path: '/index/jxzr/:id',
		component: jxzr,
	},
	{
		// 工地信息
		name: 'gdxx',
		path: '/index/gdxx/:id',
		component: gdxx,
	},
	{
		// 机械维修
		name: 'jxwx',
		path: '/index/jxwx/:id',
		component: jxwx,
	},
	{
		// 个人中心
		path: '/userinfo',
		component: userInfo,
	},
	{
		// 信息发布
		path: '/setup',
		component: SetUp,
	},
	{
		// 隐私政策
		path: '/privacy',
		component: Privacy,
	},
	{
		// 登录
		path: '/login',
		component: Login,
	},
	{
		// 注册
		path: '/reg',
		component: Register,
	}
]

export const Routers = createRouter({
	history: createWebHashHistory(),
	routes
});


// ["ADMIN", "BACKEND_USER", "OP","SYS_OP","SYS_ADMIN"]
//

Routers.beforeEach((to, from, next) => {
	if (to.meta.isLogin) {
		const token = localStorage.getItem('token');
		if (!token) {
			next({
				name: 'login'
			});
		} else {
			//先验证token的有效性，在进行页面是否跳转的判断
			const nowTime = new Date().getTime();
			const expire = JSON.parse(token).expire;
			if (expire >= nowTime) {
				console.log('token有效');
				const roles = JSON.parse(token).roles;
				let isRoles;
				roles.forEach((item) => {
					if (to.meta.roles.includes(item)) {
						isRoles = true;
					}
					return;
				});
				if (isRoles) {
					next();
				} else {
					next({
						name: 'error'
					});
				}
			} else {
				// ElMessage.warning('登录过期，请重新登录');
				localStorage.removeItem('token');
				next({
					name: 'login'
				});
			}
		}
	} else {
		next();
	}
});

// export interface RouterMap {
// 	[key: string]: RouteRecordRaw;
// }

export default Routers; //将路由缺省暴露出去，其他文件才可访问