<template>
	<div class="app-body" v-for="item in jxzldata" :key="item.id">
		<div>
			<!-- head -->
			<div class="app-banner__page sticky">
				<div class="app-banner__page-inner" style="background: rgba(0, 0, 0, 0.35)">
					<div class="app-container">
						<div class="inner">
							<h1 class="title">{{ item.title }}</h1>
						</div>
					</div>
				</div>
			</div>

			<!-- body -->
			<div id="submenu-sticky">
				<!-- 方案介绍 -->
				<div class="wrapper-pattern-5" id="column-intor">
					<div class="app-container">
						<div class="wrapper-content">
							<div class="wrapper-content app-medical-intor">
								<article class="description_con">
									<div class="gd-title">
										<h1 style="font-size: 30px">{{ item.title }}</h1>
									</div>
									<div class="inner-intor" v-if="item.innerIntroTitle">
										<div class="gd-range">
											<p style="font-size: 20px">
												<span>【价格】</span>
												<span>{{ item.price }}</span>
											</p>
										</div>
										<br />
										<p style="font-size: 20px">【{{ item.innerIntroTitle }}】</p>
										<br />
										<div class="jx-item-box">
											<div v-for="i in item.innerIntro">
												<div class="jx-item" v-if="item.innerIntro">
													<span class="jx-title">{{ i.title }}：</span>
													<span class="jx-text">{{ i.text }}</span>
												</div>
											</div>
										</div>
									</div>
									<div class="gd-range">
										<p style="font-size: 20px">
											<span>【相关内容】</span>
										</p>
										<br />
										<p style="font-size: 16px; line-height: 50px; margin-left: 30px">
											<span>{{ item.content }}</span>
										</p>
									</div>

									<div class="gd-introduce">
										<p style="font-size: 20px">【相关介绍】</p>
										<br />
										<p style="font-size: 16px; line-height: 50px; margin-left: 30px">
											{{ item.introduce }}
										</p>
									</div>

									<div class="gd-image">
										<p style="font-size: 20px">【相关展示】</p>
										<br />
										<br />
										<div class="img-box" v-for="img in item.image">
											<img :src="img" alt="" />
										</div>
									</div>
								</article>
							</div>
						</div>
					</div>
				</div>
				<!-- 方案介绍 -->
				<footzxVue></footzxVue>
			</div>
		</div>
	</div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import footzxVue from '@/components/foot-zx/foot_zx.vue';

import { onMounted } from 'vue';

// 机械租赁信息
import { JXZLDataJS } from '@/common/utils/jxzldata';

// 接收路由传参
let route = useRoute();
const jxitemid = route.path.substr(route.path.length - 4, 4);

// 机械租赁信息
const jxzldata = JXZLDataJS().filter((item) => {
	return item.id == jxitemid;
});

console.log('---------------------------------');
console.log(jxitemid);
console.log(jxzldata);

onMounted(() => {
	window.scrollTo({
		top: 0
		// behavior: 'smooth' // 平滑滚动（可选）
	});
});
</script>

<style>
.jx-item-box {
	margin-left: 30px;
	font-size: 16px;
}
.jx-item {
	display: flex;
	align-items: center;
	line-height: 40px;
}

.description_con > div {
	margin: 30px 0;
}

.img-box {
	margin-left: 30px;
}
</style>
