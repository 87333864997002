/**
 * 基础前后端交互工具类</br>
 * 1，session的get，set，clear方法
 * 2，token的set，get方法
 * 3，小红椒专用ajax的api请求方法
 */
import {
	getBase
} from "./Base";
import axios from "axios";


//返回错误码常量预定义
const RC = {
	SUCCESS: 1,
	FAILURE: 0,
	NOSESSION: "no session",
	AJAXERROR: "ajax error"
};

function localGetSession(key) {
	let data = {}
	data = localStorage.getItem(key) || {}
	try {
		if (data) {
			return {}
		}
		data = JSON.parse(data)
	} catch (e) {
		console.error(e)
	}
	return data;
}

function localSetSession(key, value) {
	let json = value;
	try {
		json = JSON.stringify(value)
	} catch (e) {
		console.error(e);
	}

	localStorage.setItem(key, json)
}

function localClearSession(key2) {
	sessionStorage.removeItem(key2)
}

function buildReq(content, token) {
	//处理签名字段

	// if (id != undefined && secret != undefined) {
	// 	req.id = id;
	// 	//按id->c的顺序连接字符串，然后再使用HmacSHA1(verifyByHmacSHA1ToID64)和密钥进行签名得到v
	// 	//console.debug("verify->>>" + req.id + "&" + req.cnt + "&" + secret);
	// 	req.v = CryptoJS.HmacSHA1((req.id + req.c), secret).toString(CryptoJS.enc.Hex);
	// }
	// console.log(JSON.parse(token) ,"====")

	let req = {
		cid: "cid", //当前客户端唯一编号
		id: "qid", //当前请求的编号
		ssn: token == undefined ? getBase().ssn : token.ssn, //当前站点序号
		uid: token == undefined ? "" : token.uid, //用户编号
		c: content, //内容（json字符串）
		t: token, //令牌AccessToken
		v: "", //签名HmacSHA1(verifyByHmacSHA1ToID64)
	};
	return req;
}

function dealResp(res, resolve, reject) {
	if (res.status == 200) {
		let rdata = res.data
		if (rdata.rc == 1) {
			resolve(rdata.c)
		} else {
			//服务器其它失败代码，直接返回resp对象
			reject(rdata)
		}
	} else {
		reject({
			rc: RC.AJAXERROR,
			c: ("AJAX Http异常>status=" + res.status + ">" + JSON.stringify(res))
		})
	}
}

function localApi(url, content) {

	//拼接base根地址
	if (!url.startsWith("/")) {
		url = getBase().host + "/" + url
	} else {
		url = getBase().host + url
	}

	//从存储读取AccessToken
	let token = JSON.parse(localStorage.getItem("token"))
	if (token === null) {
		token = {
			ssn: getBase().ssn, //当前站点序号，用于多站点时区分，单站点时为空即可
		}
	}

	console.log('获取的token');

	//构造请求
	let req = buildReq(content, token)

	//转换成字符再传，如果是对象，可能axios会自动设置content-type，导致出现options包
	//将来有问题，也可以强制指定content-type为text/plain这样的简单请求类型
	let data = JSON.stringify(req);

	// console.error(url + ">>>" + data);
	// console.log("请求长度:" + "------------" + data.length);

	axios.defaults.headers = {
		"Content-Type": "text/plain"
	}
	return new Promise((resolve, reject) => {
		axios.post(url, data).then(function(res) {
			dealResp(res, resolve, reject);
		}).catch(function(readyState, status, error) {
			// console.error("axios>>>" + error);
			// console.error(readyState);
			reject({
				rc: RC.AJAXERROR,
				c: ("AJAX Client异常>" + readyState + "&" + status + "&" + error)
			})
		})
	})
}

export default {

	/**
	 * 设置Session
	 *
	 * @param {string} key 关键字
	 * @param {any} value 内容
	 */
	setSession: localSetSession,

	/**
	 * 获取Session
	 *
	 * @param {string} key 关键字
	 */
	getSession: localGetSession,

	/**
	 * 清空Session
	 *
	 * @param {string} key 关键字
	 */
	clearSession: localClearSession,


	/**
	 *  远程调用方法的封装
	 *
	 * @param url 接口地址
	 * @param content 接口参数对象
	 */
	api: localApi,

	getToken: function() {
		return localGetSession("AccessToken")
	},

	setToken: function(token) {
		localSetSession("AccessToken", token)
	},
}