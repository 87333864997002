export function JXXXDataJS() {
	const jxdata = [
		// jx01
		{
			id: 'jx01',
			title: '山东临工E675H国四挖掘机',
			intro: '具有先进的动力系统、操作简便、功能强大、性能优异、结构合理、维护方便、安全可靠、环保节能，适用于各种挖掘作业。',
			image: ['https://imgproduct.cehome.com/221226/3d599f8a2aadacdf5071845a51aaa19e.jpg!w320'],
			innerIntroTitle: '重要参数',
			innerIntro: [{
					title: '铲斗容量',
					text: '0.16-0.34(0.3) m³'
				},
				{
					title: '额定功率',
					text: '35.9 kw/rpm'
				},
				{
					title: '整机工作重量',
					text: '7600 kg'
				},
				{
					title: '最大挖掘深度',
					text: '4160 mm'
				},
				{
					title: '铲斗挖掘力',
					text: '50 kN'
				}
			],
			advantage: [
				'引进先进动力系统：采用低速大扭矩、节油环保的动力系统，动力充沛且稳定。',
				'操作简便： 采用智能化操作系统， 使操作人员能够轻松掌握和操作挖掘机。',
				'功能强大： 具备多项功能， 如挖掘、 装载、 平整等， 可满足不同工作需求。',
				'性能优异： 具备强劲的挖掘力和装载力， 能够轻松应对各种复杂的作业环境。',
				'结构合理： 采用优质钢材制造， 具有结构坚固、 使用寿命长等特点。',
				'维护方便： 具备自动诊断系统， 可以实现故障自诊断， 方便维修和保养。',
				'安全可靠： 配备安全防护装置， 如防滑梯、 防护网等， 提供操作员和周围环境的安全保障。',
				'环保节能： 符合国家排放标准， 采用低噪声、 低排放的技术， 对环境友好。'
			],
			introduce: '山东临工E675H国四挖掘机是一款符合国四排放标准的挖掘机。该挖掘机采用先进的技术和设计，具有出色的性能和可靠的质量。首先，该挖掘机配备了强大的发动机，能够提供高功率和扭矩输出，使其在各种工况下都能够高效地工作。同时，其采用了先进的电子控制系统，能够更精确地控制发动机和液压系统的工作，提高了整机的工作效率和稳定性。其次，该挖掘机的液压系统采用了优质的液压元件和先进的液压控制技术，能够实现快速、平稳的动作，提高了挖掘和装载的效率。同时，该挖掘机还具备良好的散热系统，能够有效降低工作温度，延长液压元件的使用寿命。此外，该挖掘机具有结构坚固、稳定可靠的特点。其采用了高强度钢板焊接，提高了整机的强度和抗振性能；同时，还采用了先进的液压缓冲和减振系统，有效降低了振动和噪音，提升了操作员的舒适性。总体来说，山东临工E675H国四挖掘机是一款性能出色、质量可靠的挖掘机。它能够高效完成各种挖掘和装载作业，并具备较高的稳定性和舒适性。它的先进技术和设计使得它成为一个可靠、值得信赖的工作伙伴。'
		},
		// jx02
		{
			id: 'jx02',
			title: '徐工XE135GA挖掘机',
			intro: '具有高效动力系统，稳定性好，操控方便，能效比高，舒适性好，维护简便，多功能性强，具有较强的适应性。',
			image: ['https://imgproduct.cehome.com/240320/cc426cf3097078fd89d90d726166b066.png!w320'],
			innerIntroTitle: '',
			innerIntro: [],
			advantage: [
				'高效动力系统：搭载先进的动力系统，具有强大的挖掘力和快速的作业效率。',
				'稳定性好：采用稳定性强的底盘结构和重心设计，提高了挖掘机的稳定性和安全性。',
				'操控方便：采用智能化的操控系统，使操作更加方便和精准。',
				'能效比高：具有优秀的燃油经济性和能源利用效率，降低了运行成本。',
				'舒适性好：配备了宽敞舒适的驾驶室和先进的减震系统，减少了操作员的疲劳感。',
				'维护简便：设计简洁易懂，易于维护和保养，降低了维修成本。',
				'多功能性强：可根据不同工况和作业需求进行快速更换配件和工具，具有较强的适应性。'
			],
			introduce: '徐工XE135GA挖掘机采用先进的液压系统，能够实现快速、灵活的工作。其动力系统强劲稳定，能够适应各种复杂工况下的作业需求。同时，该挖掘机具有良好的稳定性和强大的抓力，能够有效提高作业效率和安全性。另外，XE135GA挖掘机采用节能技术，具有较低的燃油消耗和排放，符合环保要求。整体来看，徐工XE135GA挖掘机在性能、稳定性、节能环保等方面表现优异，是一款性价比较高的挖掘机产品。'
		},
		// jx03
		{
			id: 'jx03',
			title: '日立ZX550LCH-6A挖掘机',
			intro: '具有高效工作能力，舒适性，节能环保，维修保养方便，多功能性，可以实现多种作业功能，提高了工作的灵活性和多样性。',
			image: ['https://imgproduct.cehome.com/240312/bbd7aef7e7710babe25f3566d6eba29b.png!w320'],
			innerIntroTitle: '重要参数',
			innerIntro: [{
					title: '铲斗容量',
					text: '3~4 m³'
				},
				{
					title: '额定功率',
					text: '296 kw/rpm'
				},
				{
					title: '整机工作重量',
					text: '54500 kg'
				}
			],
			advantage: [
				'高效工作能力：日立ZX550LCH-6A挖掘机配备了强大的发动机和先进的液压系统，能够快速高效地进行挖掘作业，提高工作效率。',
				'舒适性：该挖掘机采用了人性化设计，驾驶室宽敞舒适，操作方便，减轻了驾驶员的疲劳感，提高了工作效率。',
				'节能环保：日立ZX550LCH-6A挖掘机采用了先进的节能技术，能够减少燃油消耗，降低碳排放，符合现代节能环保的要求。',
				'维修保养方便：该挖掘机采用了模块化设计，便于维修保养，减少停机时间，提高了设备的可靠性和稳定性。',
				'多功能性：日立ZX550LCH-6A挖掘机配备了多种不同型号的配件和附件，可以实现多种作业功能，提高了工作的灵活性和多样性。'
			],
			introduce: '日立ZX550LCH-6A挖掘机采用了先进的液压系统和高效的发动机，具有强大的挖掘能力和稳定性。其标准配置下最大挖掘深度达到了7.74米，最大挖掘高度达到了11.22米。此外，该挖掘机还配备了智能化的控制系统，能够实现精准的操作和节能的表现。整机结构设计合理，提高了机械的耐久性和稳定性，适用于各种复杂的工程施工环境。综合来看，日立ZX550LCH-6A挖掘机具有出色的性能和可靠性，适用于大型土方开挖和挖掘作业。'
		},
		// jx04
		{
			id: 'jx04',
			title: '三一重工STC1300C8-6起重机',
			intro: '具有高起重能力、大幅度工作范围、操控方便、安全稳定、高可靠性和环保节能等优点，适用于各种重型起重任务。',
			image: ['https://imgproduct.cehome.com/231219/0429499ceeb4482c5cb1921a810ee8b5.png!w320'],
			innerIntroTitle: '重要参数',
			innerIntro: [{
					title: '最大额定起重量',
					text: '130 t'
				},
				{
					title: '底盘发动机型号',
					text: '潍柴WP10.5H430E62'
				},
				{
					title: '额定功率',
					text: '316/1900 kw/rpm'
				},
				{
					title: '最高行驶速度',
					text: '80 km/h'
				},
				{
					title: '工作重量',
					text: '54.99 t'
				}
			],
			advantage: [
				'高起重能力：STC1300C8-6起重机的最大起重能力为130吨，可以轻松应对各种重型起重任务。',
				'大幅度工作范围：起重机的主臂长度为60米，配备了8个伸缩节段，可以灵活调整工作范围，适应不同的工作需求。',
				'操控方便：STC1300C8-6起重机采用先进的电控系统，操作简单方便，提高了工作效率。',
				'安全稳定：起重机配备了多种安全系统，如超载保护装置、倾覆保护装置等，确保了作业安全稳定。',
				'高度可靠性：三一重工作为全球知名的起重机制造商，产品质量可靠，使用寿命长。',
				' 环保节能：STC1300C8-6起重机采用先进的液压系统和发动机技术，具有较低的燃油消耗和排放量，符合环保要求。'
			],
			introduce: '三一重工STC1300C8-6起重机是一款性能卓越的设备。该起重机具备重量大、起重力强的特点，最大起重量可达130吨，起重力矩高达4188吨米。其主臂长度达52米，最大组合长达72米，可满足不同工程项目的需要。STC1300C8-6起重机采用了可靠的移动底盘设计，配备有良好的悬挂系统和先进的油气传动装置。这使得起重机运动灵活，操作稳定，提高了工作效率和安全性。同时，配备了强大的动力系统和高效的液压系统，能够适应不同工况的需求。该起重机具备先进的自动液压调平系统和智能化控制系统，能够提供精确的调平控制和高精度的操作。操作员可以通过触摸屏进行集中操作控制，实现简单易懂的操作界面。此外，起重机还具备优良的操纵性能和人性化的设计，可提供便捷的维护和维修。总的来说，三一重工STC1300C8-6起重机具有强大的起重能力、稳定的运动性能，配备先进的控制系统和人性化的设计，能够满足各种复杂工程项目的需要。它是一台性能卓越、操作方便、安全可靠的起重设备。'
		},
		// jx05
		{
			id: 'jx05',
			title: '三一重工SS270V装载机',
			intro: '可快速完成各种作业，稳定性好，稳定性好，能效比高，维护方便，舒适性强，具有较高的使用灵活性，提供广泛的售后服务支持。',
			image: ['https://imgproduct.cehome.com/240422/b351c7c185ac47313f8949cf2ecfbe27.png!w320'],
			innerIntroTitle: '重要参数',
			innerIntro: [{
					title: '额定载重量',
					text: '1290 kg'
				},
				{
					title: '铲斗容量',
					text: '0.5 m³'
				},
				{
					title: '发动机型号',
					text: '洋马4TNV98CT'
				},
				{
					title: '额定功率 ',
					text: '53.7/2400 kw/rpm'
				}
			],
			advantage: [
				'高效作业：SS270V装载机配备了强劲的动力系统，可快速完成各种装载、挖掘和搬运作业，提高工作效率。',
				'稳定性好：采用了先进的液压系统和底盘设计，确保了装载机在作业过程中的稳定性和平稳性。',
				'操作便捷：装载机配备了人性化设计的操作台和操纵杆，使操作者可轻松快捷地掌握机器的各项功能和操作。',
				'能效比高：SS270V装载机采用了节能、环保的发动机和系统设计，能够在较低排放下保持高效作业。',
				'维护方便：机器结构合理，维护和保养方便。同时，三一重工提供广泛的售后服务支持，保障客户在使用过程中享有便捷的维护保障。',
				'舒适性强：SS270V装载机的驾驶室设计宽敞舒适，配备座椅调节和空调系统，为操作者提供良好的工作环境。',
				'多功能性：该装载机不仅可用于土方开挖、装载等基础工程作业，还可通过更换配件实现多种作业需求，具有较高的使用灵活性。'
			],
			introduce: '三一重工SS270V装载机是一款配备160马力柴油发动机的产品，装载能力为2.7立方米。该装载机具有卓越的工作效率和稳定性，适用于各种建筑、道路施工等场景。同时，其操控性能优良，操作简便，操作人员可以轻松完成各项作业任务。另外，该装载机配置了先进的液压系统和智能控制技术，可以实现快速、准确的作业操作。综合来看，SS270V装载机在实际使用中表现出色，是一款性能稳定、操作便捷的优秀产品。'
		},
		// jx06
		{
			id: 'jx06',
			title: '厦工XG958K装载机',
			intro: '具有承载能力强、高效、燃油经济、操控灵活、人性化设计、耐用可靠、维修保养便捷以及安全性能好等优点。',
			image: ['https://imgproduct.cehome.com/221019/a6c5d42ec6afda867664071f94051ae4.jpg!w320'],
			innerIntroTitle: '重要参数',
			innerIntro: [{
					title: '额定载重量',
					text: '5000 kg'
				},
				{
					title: '整机操作重量',
					text: '17250 kg'
				},
				{
					title: '铲斗容量',
					text: '2.2～4.5（标配3.0斗） m³'
				},
				{
					title: '最大-卸载高度',
					text: '3170 mm'
				},
				{
					title: '挖掘力（掘起力）',
					text: '175 kN'
				},
				{
					title: '发动机型号',
					text: '上柴SC10E220G4'
				},
				{
					title: '额定功率',
					text: '162/2000 kw/rpm'
				},
				{
					title: '燃油箱',
					text: '310 L'
				}
			],
			advantage: [
				'强大的承载能力：最大承载能力达到了5000kg，能够满足大部分装载物料的需求。',
				'高效的工作能力：采用独特的液压系统和机械结构设计，工作效率高，提升速度快。',
				'燃油经济性：采用了低排放、低噪音的发动机，燃油经济性良好。',
				'操控灵活：采用可调节转向比例的液压转向系统，操作灵活，转向更加方便。 ',
				'人性化设计：驾驶舱宽敞舒适，前后视野开阔，操作台布局合理，使用更加方便。 ',
				'耐用可靠：采用优质的钢材和焊接工艺，结构牢固，寿命长，可靠性高。',
				'维修保养方便：关键部件易于维修和更换，维护成本低。',
				'安全性能好：采用高质量的刹车系统和安全保护装置，操作更加安全可靠。 '
			],
			introduce: 'XG958K装载机是一款长轴距国四产品，整机稳重大气，可靠高效。该机牵引力大，掘起力大，铲装效率高，特别适合重工况物料的铲运及装卸作业，广泛应用于矿山、港口、砂石厂，大型工程等作业场所。'
		},
		// jx07
		{
			id: 'jx07',
			title: '柳工CLG9075E挖掘机',
			intro: '具有强大的工作能力，精确的操作性，精确的操作性，低噪音和低排放，具有良好的耐久性和可靠性。',
			image: ['https://imgproduct.cehome.com/170921/80dc1ccfa291ab7adea2871bc9ebf87c.JPG!w320'],
			innerIntroTitle: '重要参数',
			innerIntro: [{
					title: '铲斗容量',
					text: '0.32 m³'
				},
				{
					title: '额定功率',
					text: '46.3/2200 kw/rpm'
				},
				{
					title: '整机工作重量',
					text: '7500 kg'
				},
				{
					title: '最大挖掘深度',
					text: '4030 mm'
				},
				{
					title: '铲斗挖掘力',
					text: '56 kN'
				},
				{
					title: '发动机型号',
					text: '洋马4TNV98'
				},
				{
					title: '燃油箱',
					text: '140 L'
				}
			],
			advantage: [
				'强大的工作能力：柳工CLG9075E挖掘机配备了高效率的发动机和液压系统，能够提供高强度的工作能力，适用于各种复杂的工作环境。',
				'精确的操作性：柳工CLG9075E挖掘机采用了精准的液压控制系统，使得操作更加灵活精准，能够准确地进行各种工作任务，提高工作效率。',
				'舒适的驾驶室：柳工CLG9075E挖掘机的驾驶室宽敞舒适，配备了舒适的座椅和先进的空调系统，为驾驶员提供良好的工作环境，减轻驾驶员的疲劳感。',
				'低噪音和低排放：柳工CLG9075E挖掘机采用了先进的发动机和噪音控制技术，具有低噪音和低排放的特点，减少对环境的污染。  ',
				'可靠的性能和耐久性：柳工CLG9075E挖掘机采用了优质的材料和先进的制造工艺，具有良好的耐久性和可靠性，能够适应各种恶劣的工作条件，保证长时间的高效工作。'
			],
			introduce: '柳工CLG9075E挖掘机装备洋马4TNV98发动机，低转大扭矩、匹配自动怠速，油耗更低。精心调校发动机、主泵的匹配，并提升油缸速度，以及各铰接点的完美设置，使得效率提高8%，油耗降低14%。柳工CLG9075E是一款出色的挖掘机，广受欢迎的原因有以下几点。首先，该挖掘机采用先进的技术和高效的动力系统，可以提供卓越的工作性能和操作效率。其配备的大功率发动机能够输出强大的动力，使其在各种工作条件下都能够高效地进行挖掘作业。此外，该挖掘机还采用了先进的液压系统和智能控制技术，实现了更精准的操作和更稳定的工作。其次，柳工CLG9075E的设计非常人性化，为操作员提供了舒适的工作环境和便捷的操作体验。驾驶舱宽敞明亮，视野开阔，操作面板布局合理，各项控制器和仪表清晰易懂，使操作员能够轻松掌握机器的情况和操作要求。此外，该挖掘机还配备了悬挂式座椅和多功能控制杆等人性化设计，提供了更舒适的操作体验和更灵活的操作方式。最后，柳工CLG9075E的可靠性和耐用性也是其备受认可的重要原因。该挖掘机采用了高强度材料和先进的制造工艺，具有高度耐磨和抗压能力，能够在严苛的工作条件下稳定运行。此外，柳工作为一家全球知名的工程机械制造商，拥有丰富的研发经验和高质量的生产体系，保证了该挖掘机的品质和可靠性。综上所述，柳工CLG9075E挖掘机凭借其卓越的工作性能、人性化的设计和可靠耐用的品质，在市场上受到广泛认可和青睐。无论是在建筑工地还是矿山等各种工作环境下，该挖掘机都能够发挥出色的作用，提高工作效率，满足用户的需求。'
		},
		// jx08
		{
			id: 'jx08',
			title: '柳工6116E压路机',
			intro: '具有良好的压实效果、强大的动力系统、人性化的操作设计、可靠的品质保证和易维护等优点，适用于各种路面压实作业。',
			image: ['https://imgproduct.cehome.com/190530/525403adb260610c152bbdb0aaa19f70.jpg!w320'],
			innerIntroTitle: '重要参数',
			innerIntro: [{
					title: '带驾驶室工作质量',
					text: '16000 kg'
				},
				{
					title: '振动频率',
					text: '30 Hz'
				},
				{
					title: '激振力',
					text: '300/180 kN'
				},
				{
					title: '额定功率',
					text: '4160 mm'
				}
			],
			introduce: '徐工XE135GA挖掘机采用先进的液压系统，能够实现快速、灵活的工作。其动力系统强劲稳定，能够适应各种复杂工况下的作业需求。同时，该挖掘机具有良好的稳定性和强大的抓力，能够有效提高作业效率和安全性。另外，XE135GA挖掘机采用节能技术，具有较低的燃油消耗和排放，符合环保要求。整体来看，徐工XE135GA挖掘机在性能、稳定性、节能环保等方面表现优异，是一款性价比较高的挖掘机产品。',
			advantage: [
				'卓越的压实效果：6116E压路机配备有高能量、低振动压路系统，能够实现高效的压实效果。其重压辊和脚轮均采用了独特的设计，使得压路机在压实作业中能够获得较大的冲击力和振动频率，从而提高了压实效果。',
				'强大的动力系统：6116E压路机装备了先进的动力系统，搭载有高功率的发动机，能够提供强大的动力输出。这使得压路机在作业过程中能够轻松应对各种复杂的路面状况，提高了工作效率。',
				'人性化的操作设计：6116E压路机采用了人性化的操作设计，配备有舒适的驾驶室和优质的座椅。驾驶员能够轻松掌握机器的操作，减轻了驾驶员的疲劳度，并提高了作业的舒适性和安全性。',
				'.可靠的品质保证：柳工是中国领先的工程机械制造商，生产的设备品质可靠。6116E压路机采用了优质的材料和先进的制造工艺，经过严格的质量检验和测试，确保了设备的稳定性和可靠性。',
				'易维护：6116E压路机的各个部件都经过精心设计，便于维护和保养。设备配备有智能化的维护提醒系统，能够及时提醒用户进行保养和维修，延长设备的使用寿命。 ',
			]
		}
	]
	return jxdata;
}