<template>
	<div class="app-body">
		<!-- 登录状态 -->
		<div class="wrapper-main" v-if="store.isLogin">
			<div style="margin: 0 auto; width: 100%; max-width: 1200px">
				<div class="welcome">
					<h1 style="font-weight: 900; color: #1aa68a">你好!&nbsp;{{ store.userInfo.name }}，欢迎进入！</h1>
					<br />
					<h4 style="color: #1aa68a">你还有{{ store.userInfo.total }}次发布信息的次数</h4>
				</div>
			</div>

			<!-- 已发布信息 -->
			<div class="app-container">
				<div style="margin: 30px auto; width: 100%; max-width: 1200px; height: 66.85vh; border: 1px solid #c8c8c8; padding: 15px; border-radius: 20px; overflow: hidden">
					<div class="app-serve-hot">
						<div class="contents">
							<div class="content show">
								<div class="setupinfo">
									<h2>已发布消息：</h2>
									<hr style="margin: 20px 0" />
								</div>
								<div class="wrapper-content" style="height: 50vh; overflow-y: scroll; scrollbar-width: 0; margin: 0; padding: 0">
									<div class="item" v-for="item in setupinfoList">
										<div>
											<h3 class="title">{{ item.INFO_USER_NAME }} - {{ item.INFO_PHONE }} - {{ item.INFO_ADDRESS }}</h3>
											<span>发布内容：</span>
											<span class="size">{{ item.CONTENT }}</span>
										</div>
										<span class="date">{{ new Date(item.TIME_CREATE).toLocaleString() }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 未登录状态 -->
		<div id="submenu-sticky" style="width: 100%; height: 81.5vh" v-if="!store.isLogin">
			<div class="app-submenu current hide">
				<div class="app-container">
					<div class="app-submenu-wrap">
						<ul class="app-submenu-crumbs">
							<li><a href="javascript:">首页</a></li>
							<li><a href="javascript:">注册</a></li>
						</ul>
					</div>
				</div>
			</div>
			<div style="height: 50px"></div>
			<div class="wrapper-main">
				<div class="app-container">
					<div style="margin: 0 auto; width: 100%; max-width: 1200px">
						<form id="Form1" method="post" name="Form1" action="javascript:">
							<div class="register-form">
								<table id="test" name="Table1">
									<tr>
										<td valign="middle">
											<table class="table_gap">
												<tr>
													<td class="TDLeft">
														<span id="lblUserName">用户名：</span>
													</td>
													<td class="TDRight">
														<input
															class="input WidthInput"
															id="tbxUserName"
															maxlength="50"
															name="tbxUserName"
															tabindex="1"
															type="text"
															required
															v-model="form.name"
														/>
													</td>
												</tr>
												<tr>
													<td class="TDLeft">
														<span id="lblPwd">密码：</span>
													</td>
													<td class="TDRight">
														<input
															class="input WidthInput"
															id="tbxPassword"
															maxlength="20"
															name="tbxPassword"
															tabindex="2"
															type="password"
															required
															v-model="form.password"
														/>
														&nbsp;
													</td>
												</tr>
												<tr>
													<td class="TDLeft">
														<span id="lblPwd">确认密码：</span>
													</td>
													<td class="TDRight">
														<input
															class="input WidthInput"
															id="tbxPassword"
															maxlength="20"
															name="tbxPassword"
															tabindex="2"
															type="password"
															required
															v-model="form.confirmPassword"
														/>
														&nbsp;
													</td>
												</tr>
												<tr>
													<td class="TDLeft">
														<span id="lblUserName">手机号：</span>
													</td>
													<td class="TDRight">
														<input
															class="input WidthInput"
															id="tbxUserName"
															maxlength="50"
															name="tbxUserName"
															tabindex="1"
															type="text"
															required
															v-model="form.phone"
														/>
													</td>
												</tr>
												<tr>
													<td align="right" nowrap="">验证码：</td>
													<td align="left" nowrap="">
														<input
															id="CheckCode_txtVerfyCode"
															maxlength="4"
															name="CheckCode:txtVerfyCode"
															tabindex="4"
															type="text"
															required
															v-model="form.validCode"
														/>
														<span id="CheckCode_lblCheckMsg" style="color: Red" @click="getvalidCode">获取验证码</span>
													</td>
												</tr>
												<tr>
													<td>&nbsp;</td>
													<td class="TDRight">
														<input class="btStyle" id="btnLogin" name="btnLogin" tabindex="5" type="submit" value="注册" @click="register" />
														&nbsp;&nbsp;&nbsp;
														<input class="btStyle" id="btnReset" name="btnReset" type="reset" value="重置" />
													</td>
												</tr>
											</table>
										</td>
									</tr>
								</table>
								<div class="explain">
									<br />
									<p>
										<b>如您已经拥有账号，则点击下列功能进行跳转</b>
										<br />
										<br />
										<router-link to="/login">在线登录</router-link>
									</p>
								</div>

								<div align="center" style="margin-bottom: 20px"></div>
								<div style="clear: both; height: 12px"></div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div style="height: 50px"></div>
		</div>
	</div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import Common from '@/common/utils/Common';
import { getBase } from '@/common/utils/Base';
import { useRouter } from 'vue-router';
import { store } from '@/store/store';
const route = useRouter();
let form = ref({
	name: '',
	password: '',
	confirmPassword: '',
	phone: '',
	validCode: ''
});

let valid = ref({});

function getvalidCode() {
	if (form.value.password != form.value.confirmPassword) {
		alert('两次密码不一致');
		return;
	}
	if (form.value.name === '') {
		alert('用户名不能为空');
		return;
	}
	if (form.value.password === '') {
		alert('密码不能为空');
		return;
	}
	if (form.value.phone === '') {
		alert('手机号不能为空');
		return;
	}

	let cnt = {
		PHONE: form.value.phone, // String 手机号
		TEMPCODE: 'SMS_215140041' // String 模板CODE（SMS_215140037：修改手机号；SMS_215140041：登录；SMS_2151：注册）40039
	};
	console.log(cnt);
	Common.api('lkUserService/getValid', cnt)
		.then((res) => {
			console.log('获取验证码成功', JSON.parse(res));
			valid.value = JSON.parse(res);
		})
		.catch(() => {
			alert('请先填写完整信息');
		});
}

// 注册
function register() {
	valid.value.CODE = form.value.validCode.trim();
	console.log(valid.value.CODE);
	let cnt = {
		name: form.value.name,
		password: form.value.password,
		phone: form.value.phone,
		SITE_SN: 'ttjx',
		smsValid: { ...valid.value }
	};
	console.log(cnt);
	Common.api('ipc/checkValidAndEnroll', cnt)
		.then((res) => {
			console.log(JSON.parse(res));
			let data = JSON.parse(res);
			store.isLogin = true;
			store.userInfo.name = data.NAME;
			store.userInfo.id = data.ID;
			store.userInfo.phone = data.PHONE;
			store.userInfo.total = data.TOTAL;
			route.push({ name: 'index' });
			alert('注册成功');
		})
		.catch((err) => {
			console.log(err);
			alert('注册失败');
		});
}

const setupinfoList = ref();

function getInfoList() {
	if (!store.isLogin) {
		return;
	}
	let cnt = {
		userId: store.userInfo.id,
		count: 20,
		offset: 0
	};
	Common.api('ipc/getPublishList', cnt).then((res) => {
		console.log(JSON.parse(res));
		setupinfoList.value = JSON.parse(res).reverse();
	});
}

onMounted(() => {
	getInfoList();
});
</script>

<style>
.Width44 {
	width: 44px;
}

body,
td {
	font-size: 14px;
}

fieldset {
	padding: 0;
	border: 0px;
}

legend {
	margin: 0px 0px 10px;
	font-size: 16px;
	color: #333;
	font-family: 'ss';
}

.left200 {
	margin-left: 0px;
}

#Table1,
#Table2,
#Table4 {
	margin: 0px auto 30px;
	width: 100%;
}

.TDLeft {
	width: 100px;
	text-align: right;
	vertical-align: top;
	line-height: 30px;
	white-space: nowrap;
}

p {
	margin: 0;
}

.input,
#ddlValidity,
#CheckCode_txtVerfyCode {
	margin-right: 5px;
	width: 200px;
	height: 30px;
	font-size: 14px;
	line-height: 30px;
	color: #555;
	border-radius: 4px;
	border: solid #d8dce8 1px;
	background: none;
}

#ddlValidity {
	width: auto;
}

.TextWidthOfDate {
	width: 50px;
}

input[type='radio'] {
	border: solid #d8dce8 1px;
}

.table_gap td {
	padding: 5px 0px;
}

.Width44 {
	width: 44px;
}

.register-form {
	margin: 0 auto;
	padding-right: 380px;
	width: 100%;
	max-width: 1200px;
	position: relative;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.register-form .explain {
	position: absolute;
	right: 0px;
	top: 0px;
	width: 350px;
	color: #666;
	line-height: 22px;
}

#RegTipLabel {
	font-size: 14px;
	color: #333;
	line-height: 24px;
	font-family: 'ss';
}

#btnLogin {
	width: 100px;
	height: 36px;
	background: rgba(5, 96, 246, 1);
	color: #fff;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}

#btnLogin:hover {
	background: rgba(5, 96, 246, 0.7);
}

#btnReset {
	width: 100px;
	height: 36px;
	background: rgba(255, 255, 255, 0);
	color: #0560f6;
	border: 1px solid #0560f6;
	border-radius: 5px;
	cursor: pointer;
}

#CheckCode_txtVerfyCode {
	width: 50px;
}

#hlkForgetPassword,
#hlkHelp {
	font-size: 12px;
}

@media (max-width: 767px) {
	.register-form {
		width: 100%;
		max-width: 100%;
		padding-right: 0px;
	}

	.register-form .explain {
		position: static;
		right: auto;
		top: auto;
		width: 100%;
	}
}

.app-header__main-product .wrap > .content .search .input {
	border: none;
}
</style>
