import {
	reactive
} from 'vue'

export const store = reactive({
	userInfo: {
		// id: getUser().userInfo.id || '',
		// phone: getUser().userInfo.phone || '',
		// name: getUser().userInfo.name || '',
		// total: getUser().userInfo.total || 0 // 剩余的发布信息的次数
		id: '',
		phone: '',
		name: '',
		total: 0 // 剩余的发布信息的次数
	},
	// isLogin: getUser().isLogin || false,
})

// function getUser() {
// 	return localStorage.getItem('user')
// }