<template>
	<div class="app-body">
		<div class="sticky"></div>
		<div id="submenu-sticky">
			<div class="app-submenu current hide">
				<div class="app-container">
					<div class="app-submenu-wrap">
						<ul class="app-submenu-crumbs">
							<li><router-link to="/index">首页</router-link></li>
							<li><router-link to="/setup">发布信息</router-link></li>
						</ul>
					</div>
				</div>
			</div>
			<div class="wrapper-main">
				<div class="app-container">
					<div style="margin: 20px auto; width: 1200px; padding: 30px 0px; overflow: scroll">
						<form name="form1" method="get" id="form1" enctype="multipart/form-data">
							<div>
								<input
									required
									type="hidden"
									name="__VIEWSTATE"
									id="__VIEWSTATE"
									value="sw0Zq+GEPjp0vXmp6xSpllkIv0na7t/u7lwlqmOXH8VNYr3mfpTPBA/z+NICopIALOSc8UM4dGUuj/oLubbsuQYKCHlZcgr6+AFCLgLORZGB0bfZXCm/hwmz+Hw="
								/>
							</div>

							<div>
								<input required type="hidden" name="__VIEWSTATEGENERATOR" id="__VIEWSTATEGENERATOR" value="D0ACE885" />
								<input
									required
									type="hidden"
									name="__EVENTVALIDATION"
									id="__EVENTVALIDATION"
									value="pI3t5RVu5311ba3dTFKJ/DLWC44HpJCFs21eZm+Gh5OI6K/9ywxGkmbplVRPC9KzccSnI9KTXcVyosYQVAzaX0fdYTsJpXFLetGu27lRr7lno3r8b+szAWZ3srgQoKEBnfri8z8+Bi9ba8av66o7FBxVoz05CutLnwBKd7W8jx7nPwRkDuOja/AxNWIY7VR5zf2bviIAKG3ixST3"
								/>
							</div>
							<div style="padding: 25px 0px 0px">
								<div id="Formbox" class="formbox" style="display: block; line-height: 43.25px">
									<div class="row cf">
										<div class="name">
											在此填写您的信息详情
											<span style="color: red">*</span>
										</div>
									</div>
									<div class="row cf">
										<div class="name-2">
											您的姓名
											<span style="color: red">*</span>
										</div>
										<div class="formItem-2">
											<input required name="txtName" type="text" id="txtName" class="i" style="width: 400px; height: 40px" v-model="form.userName" />
										</div>
										<div class="name-2">
											您的地址
											<span style="color: red">*</span>
										</div>
										<div class="formItem-2">
											<input required name="txtJob" type="text" id="txtJob" class="i" style="width: 400px; height: 40px" v-model="form.address" />
										</div>
									</div>
									<div class="row cf">
										<div class="name-2">
											您的联系方式
											<span style="color: red">*</span>
										</div>
										<div class="formItem-2">
											<input required name="txtPhone" type="text" id="txtPhone" class="i" style="width: 400px; height: 40px" v-model="form.phone" />
										</div>
									</div>
									<div class="row cf">
										<div class="name-2">
											您所在的公司
											<span style="color: red">*</span>
										</div>
										<div class="formItem">
											<input
												required
												name="txtCompanyName"
												type="text"
												id="txtCompanyName"
												class="i"
												style="width: 560px; height: 40px"
												v-model="form.company"
											/>
										</div>
									</div>
									<div class="row">
										详细内容
										<span style="color: red">*</span>
										<br />
										<textarea name="txtQuestionMemo" rows="2" cols="20" id="txtQuestionMemo" v-model="form.content"></textarea>
									</div>
									<div class="center">
										<input required type="submit" name="btnSave" value="确认发布" id="btnSave" class="btn" style="width: 130px; height: 30px" @click="submit" />
									</div>
									<div class="row" style="color: Red; font-size: 12px" v-if="store.userInfo.total <= 0">*后续需收费4元</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup>
import { ref } from 'vue';
import { store } from '@/store/store';
import Common from '@/common/utils/Common';

let form = ref({
	userName: '',
	address: '',
	phone: '',
	company: '',
	content: ''
});

function submit() {
	if (!store.isLogin) {
		alert('请先登录');
		return;
	}
	if (form.value.userName === '') {
		alert('请输入您的姓名！');
		return;
	}
	if (form.value.address === '') {
		alert('请输入您的地址！');
		return;
	}
	if (form.value.phone === '') {
		alert('请输入您的联系方式！');
		return;
	}
	if (form.value.content === '') {
		alert('请输入您的发布内容！');
		return;
	}

	let cnt = {
		userName: form.value.userName,
		address: form.value.address,
		phone: form.value.phone,
		company: form.value.company,
		content: form.value.content,
		total: store.userInfo.total,
		userId: store.userInfo.id
	};
	Common.api('ipc/publish', cnt).then((res) => {
		console.log(JSON.parse(res));
		store.userInfo.total = store.userInfo.total - 1;
		alert('发布成功');
	});
}
</script>

<style></style>
