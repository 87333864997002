export function JXWXDataJS() {
	const jxdata = [
		// wx01
		{
			id: 'wx01',
			title: '宇瑞通机械设备·专业维修',
			intro: '宇瑞通机械设备·专业维修天车、电机、风机、风泵、水泵、空压机、变频。',
			price:'面议（按维修项评估）',
			introduce: '专业维修各种机电设备：交流电机、直流电机、滑环电机、伺服电机、防爆电机、步进电机、变频电机、锥形电极、减速机、空压机、风机等等维修、安装及配件销售。专业维修各种水泵： 风泵、 多级泵、 深井泵、 污水泵、 潜水泵、 清水泵、 管道泵、 屏蔽泵等等维修、 安装及配件销售。天车专业维修： 0.5--50 吨天车维修、 安装、 改装、 检验及配件销售。冷却塔维修、 更换填料及维护。中央空调清洗及维护。专业组装 配电箱、 配电柜、 变频器维修。提供各种水、 气排放检测服务。 ',
			innerIntroTitle: '基本信息',
			innerIntro: [{
					title: '类别',
					text: '工业机械设备维修 工程机械设备维修'
				},
				{
					title: '服务区域',
					text: '南开  和平  河北  红桥  塘沽  东丽  西青  北辰  武清  宝坻'
				},
				{
					title: '联系人',
					text: '邱经理'
				},
				{
					title: '联系电话',
					text: '17612264838'
				},
				{
					title: '商家地址',
					text: '北辰 -  青光  - 工业园区'
				}
			],
			image: [
				'https://pic1.58cdn.com.cn/p1/big/n_v2e53d350d2f674cb4ab080456f24a7ce7.jpg?w=338&h=253',
				'https://pic6.58cdn.com.cn/p1/big/n_v225d00cddc96b479f8fbc6f919f5a2dde.jpg?w=338&h=253',
				'https://pic8.58cdn.com.cn/p1/big/n_v2d0914850a34148e2b7f0d89e7dea31bb.jpg?w=338&h=253',
				'https://pic4.58cdn.com.cn/p1/big/n_v2fa23264582f74786b0b0f3d7d5d57ac3.jpg?w=338&h=253',
				'https://pic3.58cdn.com.cn/p1/big/n_v2ed513a18f2d2426e821e6fc7e3a15b26.jpg?w=338&h=253'
			],
		},
		// wx02
		{
			id: 'wx02',
			title: '京诚发电机维修',
			intro: '京诚发电机，维修保养，专业发电机，专业发电车，UPS电源车，24小时随叫随，租赁·全天津',
			price:'面议（按维修项评估）',
			introduce: '大中小发电机租，应急电源车租售，功率50KW之2000KW，维修保养。',
			innerIntroTitle: '基本信息',
			innerIntro: [{
					title: '类别',
					text: '工程机械设备维修'
				},
				{
					title: '服务区域',
					text: '西青'
				},
				{
					title: '联系人',
					text: '李先生'
				},
				{
					title: '联系电话',
					text: '17695724340'
				},
				{
					title: '商家地址',
					text: '西青 -  大寺  - 地铁6号线'
				}
			],
			image: [
				'https://pic3.58cdn.com.cn/wuxian/794/n_v315ad9d3b08a647109471db0a4faf0636.jpg?w=338&h=253',
				'https://pic7.58cdn.com.cn/wuxian/794/n_v3f895ed89c87f44648cdb5acec71de2fc.jpg?w=338&h=253',
				'https://pic2.58cdn.com.cn/wuxian/794/n_v35de1179dba5540bab441f7360f6fb1c2.jpg?w=338&h=253'
			],
		},
		// wx03
		{
			id: 'wx03',
			title: '天津丰亿兴达机电维修',
			intro: '天津丰亿兴达机电·服务于全天津市，维修电机水泵，维修变频器，维修减速机，维修机电设备',
			price:'面议（按维修项评估）',
			introduce: '丰亿兴达是集机电设备、水处理设备、起重设备、机电设备、高低压电力控制设备、风机设备、管道销售、设计、安装、改造为一体的公司。主要承接维修各种型号电机(交直流、同步异步、防爆、特种等)、水泵（进口国产、污水泵、多级泵、管道泵、离心泵、真空泵等)、风机(各式轴流、柜式、离心) 、各式减速机,冷却塔等。',
			innerIntroTitle: '基本信息',
			innerIntro: [{
					title: '类别',
					text: '工程机械设备维修'
				},
				{
					title: '服务区域',
					text: '津南  东丽'
				},
				{
					title: '联系人',
					text: '赵经理'
				},
				{
					title: '联系电话',
					text: '17695944426'
				},
				{
					title: '商家地址',
					text: '东丽 -  空港经济区  - 天津市东丽区金桥街金桥工业园'
				}
			],
			image: [
				'https://pic6.58cdn.com.cn/p1/big/n_v22eddf1fd1754483aa1f10d3071e514b5.jpg?w=338&h=253'
			]
		},
		// wx04
		{
			id: 'wx04',
			title: '天津鑫奥顺机械设备维修',
			intro: '天津鑫奥顺机械设备维修·维修设备、维修发电机、水泵维修、维修保养、维修变频器',
			price:'面议（按维修项评估）',
			introduce: '可提供维修设备、维修发电机、水泵维修、维修保养、维修变频器等服务。常年维修维护；电动机，电焊机，通风机，空压机，管道泵，循环泵，潜水泵，污水泵，消防泵，化工泵，真空泵，机电设备，电动机，水泵，变频器，PLC控制系统，销售自来水泵房水泵，自来水泵房水泵，不锈钢稳压泵，补水泵，排水泵，排污泵。维修保养业务。',
			innerIntroTitle: '基本信息',
			innerIntro: [{
					title: '类别',
					text: '工程机械设备维修'
				},
				{
					title: '服务区域',
					text: '静海  红桥  开发区  和平  大港  宝坻  蓟州  汉沽  宁河  滨海新区'
				},
				{
					title: '联系人',
					text: '程师傅'
				},
				{
					title: '联系电话',
					text: '17695949478'
				},
				{
					title: '商家地址',
					text: '东丽 -  程林街  - 天津东丽万达广场'
				}
			],
			image: [
				'https://pic8.58cdn.com.cn/lbghy/hypost/n_v32174f860aa2b44309e0af314a0f4f223.jpg?w=338&h=253'
			]
		},
		// wx05
		{
			id: 'wx05',
			title: '启航起重机设备维修',
			intro: '启航起重机设备维修·天津起重机销售 天车制作 龙门吊维修保养',
			price:'面议（按维修项评估）',
			introduce: '专用生产单双梁起重机，门式起重机，桥式起重机，电动葫芦，液压升降平台，液压升降货梯，等起重机配件，龙门吊制作，安装维修保养业务！有专业团队，做到每人持证上岗，24小时待命，为您企业保驾护航！欢迎咨询！',
			innerIntroTitle: '基本信息',
			innerIntro: [{
					title: '类别',
					text: '变频机械设备维修 工业机械设备维修 工程机械设备维修'
				},
				{
					title: '服务区域',
					text: '东丽  滨海新区  西青  塘沽  北辰  武清  大港  宝坻  河北  静海'
				},
				{
					title: '联系人',
					text: '许东潘'
				},
				{
					title: '联系电话',
					text: '17694852644'
				},
				{
					title: '商家地址',
					text: '北辰 -  双街镇  - 双街新家园'
				}
			],
			image: [
				'https://pic1.58cdn.com.cn/p1/big/n_v2b8ce5865bed34bd9a527ba7df0fb44ff.jpg?w=338&h=253'
			]
		},
		// wx06
		{
			id: 'wx06',
			title: '致格机电有设备维修',
			intro: '致格机电有设备维修·水泵维修，电机维修，风机维修，空压机冷却塔等维修',
			price:'面议（按维修项评估）',
			introduce: '维修电机，水泵，风机，空压机，冷却塔，机电类维保维护，管道工程，厂房改造。电机维修、水泵维修、风机维修、空压机维修、冷却塔维修、机电维修。水泵维修，电机维修，风机维修，。承包:土建工程，厂房改造，管道焊接，发电机',
			innerIntroTitle: '基本信息',
			innerIntro: [{
					title: '类别',
					text: '工业机械设备维修 工程机械设备维修'
				},
				{
					title: '服务区域',
					text: '北辰  西青  塘沽  南开  津南  武清  河东  河西  东丽  河北'
				},
				{
					title: '联系人',
					text: '何先生'
				},
				{
					title: '联系电话',
					text: '4008586078转1760'
				},
				{
					title: '商家地址',
					text: '北辰 -  小淀  - 花香漫城'
				}
			],
			image: [
				'https://pic6.58cdn.com.cn/p1/big/n_v2183acedd62144e8fa35c33991d673f58.jpg?w=338&h=253'
			]
		},
		// wx07
		{
			id: 'wx07',
			title: '杰顺通机电维修',
			intro: '杰顺通机电维修中心·维修保养、电机维修、维修变频器、直流电机、电机水泵',
			price:'面议（按维修项评估）',
			introduce: '该商家可提供维修保养、电机维修、维修变频器、直流电机、电机水泵等服务。各类电机，水泵，风机维修。空调循环泵，立式多级管道泵、立式多级离心泵、卧式多级离心泵、屏蔽泵、排污泵、深井泵、轴流泵、混流泵、消防泵、生活泵、集井泵等产品维修保养。',
			innerIntroTitle: '基本信息',
			innerIntro: [{
					title: '类别',
					text: '工业机械设备维修 工程机械设备维修'
				},
				{
					title: '服务区域',
					text: '西青  塘沽  南开  津南  武清  河东  河西  东丽  河北  北辰'
				},
				{
					title: '联系人',
					text: '程立杰'
				},
				{
					title: '联系电话',
					text: '17694894592'
				},
				{
					title: '商家地址',
					text: '东丽 -  空港经济区  - 电动机水泵维修'
				}
			],
			image: [
				'https://pic6.58cdn.com.cn/p1/big/n_v227f3e7a978454fdbad303699fd9e2686.jpg?w=338&h=253',
			]
		},
		// wx08
		{
			id: 'wx08',
			title: '天津宏晨兴旺机电设备',
			intro: '天津市宏晨兴旺机电设备安装工程·维修各种电机 水泵 风机 承接基坑护坡 机床加工',
			price:'面议（按维修项评估）',
			introduce: '具有专业维修及销售各种电机，水泵，空调，本公司拥有丰富的维修经验，专业团队，本公司承接，机床加工，不锈钢焊接，基坑护坡，边坡支护，管道安装设计。',
			innerIntroTitle: '基本信息',
			innerIntro: [{
					title: '类别',
					text: '工业机械设备维修 工程机械设备维修'
				},
				{
					title: '服务区域',
					text: '天津周边'
				},
				{
					title: '联系人',
					text: '张经理'
				},
				{
					title: '联系电话',
					text: '17694864318'
				},
				{
					title: '商家地址',
					text: '东丽 -  先锋路  - 天津机械设备'
				}
			],
			image: [
				'https://pic1.58cdn.com.cn/p1/big/n_v2912f5d2aac264f2b83651d516cf194f2.jpg?w=338&h=253'
			]
		}
	]
	return jxdata;
}