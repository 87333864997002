<template>
	<div class="app-body" v-for="item in jxdataitem" :key="item.id">
		<!-- head -->
		<div class="app-banner__page sticky">
			<div class="app-banner__page-inner" style="background: rgba(0, 0, 0, 0.35);">
				<div class="app-container">
					<div class="inner">
						<h1 class="title">{{item.title}}</h1>
						
					</div>
				</div>
			</div>
			<!-- <div class="user-panel" style="text-align: center; background: url()">
				发布者信息
				<div class="user-avatar" style="text-align: center">
					<img src="static/picture/moren.jpg" alt="User Avatar" />
				</div>
				<div class="user-info">
					查看该用户更多信息
					<h3 title="点击查看个人中心">乱世浮生</h3>
					<router-link to="/userinfo" title="点击查看个人中心"><button>查看ta的主页</button></router-link>
				</div>
			</div> -->
		</div>

		<!-- body -->
		<div id="submenu-sticky">
			<!-- 方案介绍 -->
			<div class="wrapper-pattern-5" id="column-intor">
				<div class="app-container">
					<div class="wrapper-content">
						<div class="wrapper-content app-medical-intor">
							<article class="description_con">
								<div class="inner-intor">
									<br />
									<p  style="font-size: 20px">【{{item.innerIntroTitle}}】</p>
									<br />
									<div class="jx-item-box">
										<div class="jx-item" v-for="i in item.innerIntro">
											<span class="jx-title">{{i.title}}：</span>
											<span class="jx-text">{{i.text}}</span>
										</div>
									</div>
								</div>
								<p style="font-size: 20px">【相关介绍】</p>
								<br />
								<p style="font-size: 16px; line-height: 50px">
									{{item.introduce}}
								</p>
								<br />
								<br />
								<p style="font-size: 20px">【性能优点】</p>
								<br />
								<p style="font-size: 16px; line-height: 50px">
									<ul style="margin-left: 50px;font-size: 16px; line-height: 50px" v-for="adv in item.advantage">
										<li>{{ adv }}</li>
									</ul>
								</p>
								<br />
								<p style="font-size: 20px">【相关展示】</p>
								<br />
								<div class="img-box" v-for="img in item.image">
									<img :src="img" alt="" />
								</div>
							</article>
						</div>
					</div>
				</div>
			</div>
			<!-- 方案介绍 -->

			<!-- 咨询联系 -->
			<footzxVue></footzxVue>
		</div>
	</div>
</template>

<script setup>
import footzxVue from '@/components/foot-zx/foot_zx.vue';

import { JXXXDataJS } from '@/common/utils/jxxxdata';

import { useRoute } from 'vue-router';
import { onMounted } from 'vue';

// 接收路由传参
let route = useRoute()
// console.log('useRoute()-->', route);
const jxitemid = route.path.substr(route.path.length-4,4);
const jxdataitem=JXXXDataJS().filter((item)=>{
	return item.id==jxitemid;
})

console.log('---------------------------------');
console.log(jxitemid);
console.log(jxdataitem)

onMounted(() => {
	window.scrollTo({
		top: 0,
		// behavior: 'smooth' // 平滑滚动（可选）
	});
});

</script>

<style>
.jx-item-box {
	margin-left: 30px;
}
.jx-item {
	display: flex;
	align-items: center;
}
</style>
